import { useVenue } from "lib/UserContext";
import Reports from "components/Reports";

function ReportsPage() {
  const venue = useVenue();

  return (
    <>
      <h1 className="text-2xl font-semibold mb-4">Reports</h1>

      {!venue ? <p>Select a venue</p> : <Reports />}
    </>
  );
}

export default ReportsPage;
