import get from "lodash/get";

export const isAdmin = (user) => user && user.role === "admin";

export const isOwner = (user) =>
  user && (isAdmin(user) || user.role === "owner");

export const currency = (string) => {
  return new Intl.NumberFormat("en-GB", {
    style: "currency",
    currency: "GBP",
  }).format(string);
};

export const acceptsPayments = (venue) => {
  return (
    get(venue, "payments.stripe.enabled", false) ||
    get(venue, "payments.stripeConnect.enabled", false) ||
    get(venue, "payments.paymentsense.enabled", false)
  );
};

export const number_format = (string) => {
  return string.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
