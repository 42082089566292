// import clsx from "clsx";
import { NavLink } from "react-router-dom";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Filters(props) {
  return (
    <nav className="flex justify-between space-x-2 mb-4 py-2 border-b border-gray-300 overflow-x-auto">
      <ul className="flex space-x-2">
        <li>
          <NavLink
            to="/orders"
            exact
            activeClassName="bg-purple-600 hover:bg-purple-600 text-white"
            className="button hover:bg-gray-200"
          >
            Current
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/orders/completed"
            exact
            activeClassName="bg-purple-600 hover:bg-purple-600 text-white"
            className="button hover:bg-gray-200"
          >
            Completed
          </NavLink>
        </li>
      </ul>

      {/* <ul className="flex space-x-2">
        <li>
          <button
            type="button"
            className={clsx(
              "button",
              props.view === "list" ? "bg-purple-600" : "hover:bg-gray-200"
            )}
            onClick={() => props.onViewChange("list")}
          >
            <FontAwesomeIcon icon="list" />
          </button>
        </li>
        <li>
          <button
            type="button"
            className={clsx(
              "button",
              props.view === "grid" ? "bg-purple-600" : "hover:bg-gray-200"
            )}
            onClick={() => props.onViewChange("grid")}
          >
            <FontAwesomeIcon icon="th" />
          </button>
        </li>
      </ul> */}
    </nav>
  );
}
