import values from "lodash/values";
import mapValues from "lodash/mapValues";
import isObject from "lodash/isObject";
import { BlockPicker } from "react-color";
import colors from "tailwindcss/colors";
import Toggle from "components/Forms/Toggle";
import Upload from "components/Forms/Upload";

// Get all Tailwind 500 colors
const COLORS = [
  "#8E24AA",
  "#304FFE",
  "#ffdd19",
  ...values(
    mapValues(colors, (color) => {
      return isObject(color) ? color[500] : color;
    })
  ),
];

const Branding = (props) => {
  const handleColorChange = (name, color) => {
    props.actions.handleChange({ target: { name, value: color.hex } });
  };

  return (
    <>
      <fieldset className="mb-4">
        <div>
          <Toggle
            label="Show Venue Address"
            name="show_address"
            checked={props.fields.show_address}
            onChange={props.actions.handleChange}
          />
        </div>
      </fieldset>

      <fieldset className="mb-4">
        <legend className="block w-full text-lg font-semibold">
          Colour Scheme
        </legend>

        <div className="grid sm:grid-cols-2 gap-4">
          <div>
            <label className="block mb-1">Primary Colour</label>

            <BlockPicker
              colors={COLORS}
              color={props.fields.colors.primary}
              onChangeComplete={(color) =>
                handleColorChange("colors.primary", color)
              }
              triangle="hide"
              width="100%"
            />
          </div>

          <div>
            <label className="block mb-1">Accent Colour</label>

            <BlockPicker
              colors={COLORS}
              color={props.fields.colors.accent}
              onChangeComplete={(color) =>
                handleColorChange("colors.accent", color)
              }
              triangle="hide"
              width="100%"
            />
          </div>
        </div>
      </fieldset>

      <fieldset className="mb-4">
        <legend className="block w-full text-lg font-semibold">
          Company Logo
        </legend>

        <Upload
          name="logo"
          value={props.fields.logo}
          onChange={props.actions.handleChange}
        />
      </fieldset>
    </>
  );
};

export default Branding;
