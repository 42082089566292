import VenuesTable from "components/VenuesTable";

function Venues() {
  return (
    <>
      <h1 className="text-2xl font-semibold mb-4">Venues</h1>

      <VenuesTable />
    </>
  );
}

export default Venues;
