import { useCollectionData } from "react-firebase-hooks/firestore";
import { AnimatePresence, motion } from "framer-motion";
import Categories from "./Categories";
import SubCategories from "./SubCategories";

const MenuBuilder = (props) => {
  const [categories] = useCollectionData(
    props.venue.ref.collection("categories").orderBy("order", "asc"),
    { idField: "id", refField: "ref" }
  );

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this category?")) {
      const ref = props.venue.ref.collection("categories").doc(id);

      await ref.delete();

      // TODO delete all products where category == id (in case we're deleting a subcategory)
      // TODO delete all catgories where parent == id (in case we're deleting a top-level category) ... then delete all products that have parent of these subcategories
    }
  };

  const handleToggle = async (id) => {
    const cat = categories.find((cat) => cat.id === id);
    if (cat) {
      const active = cat.hasOwnProperty("active") ? cat.active : true;
      await cat.ref.update({ active: !active });
    }
  };

  return (
    <AnimatePresence exitBeforeEnter>
      {categories && (
        <motion.div
          key={`menu-${props.venue.id}`}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
        >
          <Categories
            venue={props.venue}
            categories={categories}
            onDelete={handleDelete}
            onToggle={handleToggle}
            subCategories={(category) => (
              <SubCategories
                venue={props.venue}
                parent={category}
                categories={categories.filter(
                  (c) => c.parent && c.parent.id === category.ref.id
                )}
                onDelete={handleDelete}
              />
            )}
          />
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default MenuBuilder;
