import { useState } from "react";
import { toast } from "react-toastify";
import head from "lodash/head";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useVenue } from "lib/UserContext";

const Single = (props) => {
  const venue = useVenue();

  const printer = head(venue.printers);

  const [loading, isLoading] = useState(false);

  const handlePrint = async () => {
    try {
      isLoading(true);

      await venue.ref.collection("print_queue").add({
        order: props.order.ref,
        timestamp: new Date(),
        location: printer.location,
      });

      toast(
        `Order #${props.order.id} has been sent to the ${printer.location} printer.`
      );
    } catch (error) {
      console.error(error);
    } finally {
      isLoading(false);
    }
  };

  return (
    <button
      type="button"
      onClick={handlePrint}
      className="button-gray relative"
      disabled={loading}
    >
      <FontAwesomeIcon icon="print" className="text-sm mr-1" /> Print
    </button>
  );
};

export default Single;
