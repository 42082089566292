import clsx from "clsx";

const Textarea = ({ label, error, ...props }) => (
  <div>
    <label className="block mb-1" htmlFor={props.id}>
      {label}
    </label>

    <textarea
      className={clsx("input resize-y h-24 mb-1", error && "has-error")}
      {...props}
    />

    {error && <span className="block text-sm text-red-500">{error}</span>}
  </div>
);

export default Textarea;
