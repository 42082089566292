import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Toggle from "components/Forms/Toggle";
import EditTableCell from "./EditTableCell";

const TableRow = (props) => {
  const handleToggle = async () => {
    await props.table.ref.update({ active: !props.table.active });
  };

  const handleDelete = async () => {
    if (window.confirm("Are you sure you want to delete this table?")) {
      await props.table.ref.delete();
    }
  };

  return (
    <motion.div
      className="mb-2 p-2 bg-gray-100 border-l-2 border-purple-600 md:flex md:items-center md:p-0"
      animate={{ opacity: !props.table.active ? 0.5 : 1 }}
      transition={{ duration: 0.2 }}
    >
      <div className="mb-2 flex items-center space-x-2 md:block md:space-x-0 md:flex-grow-0 md:flex-shrink-0 md:w-24 md:mb-0 md:p-2 md:text-center">
        <div className="flex-initial font-bold md:hidden">Number:</div>
        <div className="flex-1">
          <EditTableCell
            table={props.table}
            name="number"
            className="md:text-center"
          />
        </div>
      </div>

      <div className="mb-2 flex items-center space-x-2 md:block md:space-x-0 md:flex-1 md:mb-0 md:p-2">
        <div className="flex-initial font-bold md:hidden">Description:</div>
        <div className="flex-1">
          <EditTableCell table={props.table} name="description" />
        </div>
      </div>

      <div className="flex space-x-4 items-center md:flex-grow-0 md:flex-shrink-0 md:p-2">
        <Toggle
          label="Active"
          name="active"
          checked={props.table.active}
          onChange={handleToggle}
        />

        <button
          type="button"
          onClick={handleDelete}
          className="flex items-center justify-center w-8 h-8 rounded bg-red-500 text-white leading-none transition hover:bg-red-600 focus:outline-none"
        >
          <FontAwesomeIcon icon="trash-alt" className="m-auto" />
        </button>
      </div>
    </motion.div>
  );
};

export default TableRow;
