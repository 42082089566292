import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useDrag, useDrop } from "react-dnd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";
import ProductName from "../ProductName";
import { currency } from "lib/helpers";
import Toggle from "components/Forms/Toggle";

const Product = (props) => {
  const ref = useRef(null);
  const mounted = useRef(null);

  // Update Firestore when order changes
  useEffect(() => {
    const updateFirestoreOrder = async () => {
      await props.product.ref.update({ order: props.index });
    };

    if (mounted.current !== null && mounted.current !== props.index) {
      updateFirestoreOrder();
    }

    mounted.current = props.index;
  }, [props.product.ref, props.index]);

  const [, drop] = useDrop({
    accept: "product",
    hover(item, monitor) {
      if (!ref.current) return;

      const dragIndex = item.index;
      const hoverIndex = props.index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) return;

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      props.onMove(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: "product",
    item: () => {
      return {
        id: props.product.id,
        index: props.index,
      };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  const inactive = props.product.active === false;

  return (
    <motion.div
      ref={ref}
      layout
      transition={{ duration: 0.2 }}
      className={`flex space-x-4 mb-2 p-2 bg-white rounded shadow-sm hover:cursor-pointer
      ${isDragging && "opacity-50"}
      ${inactive && "opacity-50"}
      `}
    >
      <div className="flex-1">
        <ProductName product={props.product} />
      </div>
      <div className="flex-0">{currency(props.product.price)}</div>

      <div className="flex space-x-2 items-start">
        <Toggle
          checked={props.product.active}
          onChange={props.onActive}
          small
        />

        <Link
          to={{
            pathname: `/product/${props.product.id}`,
            state: { category: props.category.id },
          }}
          className="flex items-center justify-center w-6 h-6 text-sm text-gray-400 rounded transition hover:text-gray-700 hover:bg-gray-200 focus:outline-none"
        >
          <FontAwesomeIcon icon="pencil-alt" />
        </Link>

        <button
          type="button"
          className="flex items-center justify-center w-6 h-6 text-sm text-gray-400 text-center rounded transition hover:text-gray-700 hover:bg-gray-200 focus:outline-none"
          onClick={props.onClone}
        >
          <FontAwesomeIcon icon="clone" />
        </button>

        <button
          type="button"
          className="flex items-center justify-center w-6 h-6 text-sm text-gray-400 text-center rounded transition hover:text-gray-700 hover:bg-gray-200 focus:outline-none"
          onClick={props.onDelete}
        >
          <FontAwesomeIcon icon="times" />
        </button>
      </div>
    </motion.div>
  );
};

export default Product;
