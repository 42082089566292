import filter from "lodash/filter";
import useCategories from "../use-categories";
import Category from "../Category";
import AddCategory from "../AddCategory";

const Categories = (props) => {
  const [categories, actions] = useCategories(
    filter(props.categories, ["parent", null]) // Top-level categories
  );

  return (
    <div className="grid grid-cols-2 gap-4 items-start xl:grid-cols-3">
      {categories.map((category, index) => (
        <Category
          key={category.id}
          category={category}
          index={index}
          onMove={actions.handleMove}
          onDelete={() => props.onDelete(category.id)}
          onToggle={() => props.onToggle(category.id)}
        >
          {props.subCategories(category)}
        </Category>
      ))}

      <AddCategory venue={props.venue} count={categories.length} />
    </div>
  );
};

export default Categories;
