import { useMemo, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import round from "lodash/round";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { currency, number_format } from "lib/helpers";

const Subcategories = (props) => {
  const [open, setOpen] = useState(true);

  const totals = useMemo(() => {
    return props.products.reduce(
      function (total, product) {
        return {
          quantity: round(total.quantity + product.quantity),
          total: round(total.total + product.total, 2),
        };
      },
      { quantity: 0, total: 0 }
    );
  }, [props.products]);

  return (
    <div>
      <button
        type="button"
        onClick={() => setOpen(!open)}
        className="flex items-center justify-between w-full p-2 text-left text-lg font-semibold appearance-none bg-gray-100 border-l-2 border-purple-600 focus:outline-none"
      >
        {props.name}

        <FontAwesomeIcon icon={open ? "minus" : "plus"} fixedWidth />
      </button>

      <AnimatePresence initial={false}>
        {open && (
          <motion.div
            className="overflow-hidden w-full"
            initial={{ height: 0 }}
            animate={{ height: "auto" }}
            exit={{ height: 0 }}
          >
            <table className="table-auto w-full mt-2 border-collapse">
              <thead className="text-left">
                <tr className="text-sm bg-gray-100">
                  <th className="w-3/5 p-2 font-bold">Name</th>
                  <th className="w-1/5 p-2 font-bold">Quantity</th>
                  <th className="w-1/5 p-2 font-bold">Sales</th>
                </tr>
              </thead>

              <tbody>
                {props.products.map((product) => {
                  return (
                    <tr
                      key={product.id}
                      className="text-sm bg-gray-100 border-t-2 border-white"
                    >
                      <td className="w-3/5 p-2">{product.name}</td>
                      <td className="w-1/5 p-2">
                        {number_format(product.quantity)}
                      </td>
                      <td className="w-1/5 p-2">{currency(product.total)}</td>
                    </tr>
                  );
                })}
              </tbody>

              <tfoot className="text-left">
                <tr className="bg-gray-100 border-t-2 border-white">
                  <td className="w-3/5 p-2 font-bold">Totals</td>
                  <td className="w-1/5 p-2 font-bold">
                    {number_format(totals.quantity)}
                  </td>
                  <td className="w-1/5 p-2 font-bold">
                    {currency(totals.total)}
                  </td>
                </tr>
              </tfoot>
            </table>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Subcategories;
