import format from "date-fns/format";
import endOfDay from "date-fns/endOfDay";
import DatePicker from "react-datepicker";
import { ThisMonthButton, ThisWeekButton, TodayButton } from "./Buttons";

const Filters = (props) => {
  const handleChange = ([startDate, endDate]) => {
    endDate = endDate ? endOfDay(endDate) : null;

    props.setState({ ...props.state, startDate, endDate });
  };

  const dateValue = () => {
    const pattern = "d MMM yyyy";

    const start = props.state.startDate
      ? format(props.state.startDate, pattern)
      : null;
    const end = props.state.endDate
      ? format(props.state.endDate, pattern)
      : null;

    if (start && !end) return start;

    return `${start} - ${end}`;
  };

  return (
    <div className="space-y-2 lg:flex lg:space-y-0 lg:space-x-2">
      <div className="overflow-hidden rounded">
        <TodayButton onClick={handleChange} {...props.state} />
        <ThisWeekButton onClick={handleChange} {...props.state} />
        <ThisMonthButton onClick={handleChange} {...props.state} />
      </div>

      <div>
        <DatePicker
          onChange={handleChange}
          startDate={props.state.startDate}
          endDate={props.state.endDate}
          selectsRange
          value={dateValue()}
          shouldCloseOnSelect={false}
          className="block w-60 max-w-full p-2 h-10 border border-gray-400 rounded"
        />
      </div>
    </div>
  );
};

export default Filters;
