import { motion, AnimatePresence } from "framer-motion";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useVenue } from "lib/UserContext";
import Search from "components/Search";
import TableRow from "./TableRow";
import useTableSearch from "./use-table-search";

const TablesTable = () => {
  const venue = useVenue();

  const [allTables] = useCollectionData(
    venue.ref.collection("tables").orderBy("number", "asc"),
    { idField: "id", refField: "ref" }
  );

  const [tables, search, setSearch] = useTableSearch(allTables);

  const handleAddTable = async () => {
    let number = allTables.length + 1;
    number = number.toString();

    venue.ref.collection("tables").add({
      number,
      description: `Table ${number}`,
      active: true,
    });

    setSearch(search ? number : "");
  };

  return (
    <AnimatePresence exitBeforeEnter>
      {allTables && (
        <motion.div
          key={`tables-${venue.id}`}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
        >
          <Search value={search} onChange={setSearch} />

          <div className="hidden text-sm md:flex">
            <div className="flex-grow-0	flex-shrink-0 w-24 p-2 text-center">
              <span className="p-1">Number</span>
            </div>
            <div className="flex-1 p-2">
              <span className="p-1">Description</span>
            </div>
          </div>

          <div className="mb-4">
            {tables.length ? (
              tables.map((table) => (
                <TableRow key={`table-${venue.id}-${table.id}`} table={table} />
              ))
            ) : (
              <p className="text-gray-500">No Tables Found</p>
            )}
          </div>

          <button
            type="button"
            onClick={handleAddTable}
            className="button-purple"
          >
            <FontAwesomeIcon className="mr-1" icon="plus" /> Add Table
          </button>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default TablesTable;
