import { AnimateSharedLayout } from "framer-motion";
import { useVenue } from "lib/UserContext";
import { acceptsPayments } from "lib/helpers";
import useOrdersTables from "./use-orders-table";
import Loader from "./Loader";
import Filters from "./Filters";
import Order from "./Order";

export default function OrdersTable(props) {
  const venue = useVenue();

  const [state, actions] = useOrdersTables(venue, props.completed);

  return (
    <>
      <Filters view={state.view} onViewChange={actions.handleViewChange} />

      <OrdersTableRender venue={venue} state={state} actions={actions} />
    </>
  );
}

function OrdersTableRender({ venue, state, actions }) {
  if (!state.loaded) return <Loader wait={500} />;

  if (!state.orders.length) {
    return <p className="p-2 text-center text-gray-500">No Orders Found</p>;
  }

  const hasPayments = acceptsPayments(venue);

  return (
    <>
      <AnimateSharedLayout>
        {state.orders.map((order) => (
          <Order
            key={`order-${venue.id}-${order.doc_id}`}
            order={order}
            payments={hasPayments}
          />
        ))}
      </AnimateSharedLayout>

      {state.hasMore && (
        <div className="mt-4 text-center">
          <button
            className="button border border-gray-300 hover:bg-gray-200 focus:outline-none"
            onClick={actions.handleLoadMore}
          >
            Load more
          </button>
        </div>
      )}
    </>
  );
}
