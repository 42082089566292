import React from "react";
import OrderItem from "./OrderItem";

const OrderItems = (props) => {
  return (
    <div className="max-w-5xl mb-8">
      <div className="hidden text-sm md:flex">
        <div className="flex-grow-0 flex-shrink-0 w-20 p-2 text-center">
          Quantity
        </div>
        <div className="flex-1 p-2">Item</div>
        <div className="flex-1 p-2">Category</div>
        <div className="flex-grow-0 flex-shrink-0 w-32 p-2 text-center">
          Unit Price
        </div>
        <div className="flex-grow-0 flex-shrink-0 w-32 p-2 text-center">
          Item Price
        </div>
      </div>

      <ul className="mb-4">
        {props.order.items.map((item) => (
          <OrderItem key={item.id} item={item} />
        ))}
      </ul>
    </div>
  );
};

export default OrderItems;
