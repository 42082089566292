import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useVenue } from "lib/UserContext";
import { functions } from "lib/firebase";

const printOrder = functions.httpsCallable("printOrder");

const OrderPrintingPassPrnt = (props) => {
  const venue = useVenue();

  const [loading, isLoading] = useState(false);

  if (!venue.printing.passprnt) return null;

  const handlePrint = async () => {
    try {
      isLoading(true);

      const { data: html } = await printOrder({
        venue: venue.id,
        order: props.order.doc_id,
      });

      let uri = "starpassprnt://v1/print/nopreview?";
      uri += "back=" + encodeURIComponent(window.location.href);
      uri += "&html=" + encodeURIComponent(html);

      window.location.href = uri;
    } catch (error) {
      console.error(error);
    } finally {
      isLoading(false);
    }
  };

  return (
    <button
      type="button"
      onClick={handlePrint}
      className="button-gray"
      disabled={loading}
    >
      <FontAwesomeIcon icon="print" className="text-sm mr-1" /> Print
    </button>
  );
};

export default OrderPrintingPassPrnt;
