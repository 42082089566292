import format from "date-fns/format";
import {
  usePDF,
  Font,
  Document,
  Page,
  View,
  Text,
  StyleSheet,
} from "@react-pdf/renderer";
import { useVenue } from "lib/UserContext";
import { getReportSummary } from "../../helpers";

Font.register({
  family: "Open Sans",
  fonts: [
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
    },
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-700.ttf",
      fontWeight: 700,
    },
  ],
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 30,
    paddingBottom: 30,
    paddingHorizontal: 30,
    fontFamily: "Open Sans",
  },
  title: {
    marginBottom: 20,
    fontSize: 30,
    textAlign: "center",
  },
  subtitle: {
    marginBottom: 8,
    fontSize: 20,
    textAlign: "center",
  },
  date: {
    marginBottom: 20,
    fontSize: 16,
    textAlign: "center",
  },
  table: {
    display: "flex",
    width: 400,
    marginLeft: "auto",
    marginRight: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderBottomWidth: 0,
    borderColor: "#6B7280",
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
    padding: 8,
    borderStyle: "solid",
    borderWidth: 0,
    borderBottomWidth: 1,
    borderColor: "#6B7280",
  },
  label: {
    fontSize: 16,
    fontWeight: 700,
  },
  value: {
    fontSize: 16,
  },
});

const PDFDocument = (props) => {
  let start = format(props.startDate, "d MMM yyyy");
  let end = format(props.endDate, "d MMM yyyy");

  return (
    <Document>
      <Page style={styles.body}>
        <Text style={styles.title}>{props.venue}</Text>
        <Text style={styles.subtitle}>Report Summary</Text>
        <Text style={styles.date}>{`${start} - ${end}`}</Text>

        <View style={styles.table}>
          <View style={styles.row}>
            <Text style={styles.label}>Total Orders:</Text>
            <Text style={styles.value}>{props.totals.orders}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.label}>Total Order Items:</Text>
            <Text style={styles.value}>{props.totals.order_items}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.label}>Average Order Value:</Text>
            <Text style={styles.value}>{props.totals.average_order_value}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.label}>Total Sales:</Text>
            <Text style={styles.value}>{props.totals.sales}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.label}>Total Tips:</Text>
            <Text style={styles.value}>{props.totals.tips}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.label}>Total Revenue:</Text>
            <Text style={styles.value}>{props.totals.revenue}</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

const ExportSummaryPDF = (props) => {
  const venue = useVenue();

  const totals = getReportSummary(props.orders);

  const [instance] = usePDF({
    document: <PDFDocument venue={venue.name} {...props} totals={totals} />,
  });

  return (
    <a
      href={instance.url}
      download={getFileName(venue.name, props.startDate, props.endDate)}
      className="block p-2"
    >
      Summary PDF
    </a>
  );
};

export default ExportSummaryPDF;

function getFileName(name, start, end) {
  const pattern = "d MMM yyyy";

  start = format(start, pattern);
  end = format(end, pattern);

  return `${name} - Summary (${start} - ${end}).pdf`;
}
