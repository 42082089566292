import { library } from "@fortawesome/fontawesome-svg-core";

import {
  faPlus,
  faMinus,
  faSpinner,
  faPencilAlt,
  faTrashAlt,
  faTimes,
  faEye,
  faArrowLeft,
  faExternalLinkAlt,
  faClone,
  faBell,
  faBellSlash,
  faPrint,
  faCheck,
  faList,
  faTh,
  faQrcode,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faPlus,
  faMinus,
  faSpinner,
  faPencilAlt,
  faTrashAlt,
  faTimes,
  faEye,
  faArrowLeft,
  faExternalLinkAlt,
  faClone,
  faBell,
  faBellSlash,
  faPrint,
  faCheck,
  faList,
  faTh,
  faQrcode,
  faDownload
);
