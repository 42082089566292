import size from "lodash/size";
import { currency } from "lib/helpers";
import OrderItemExtras from "./OrderItemExtras";

const OrderItem = (props) => {
  return (
    <li className="mb-2 p-2 bg-gray-100 border-l-2 border-purple-600 md:flex md:p-0">
      <div className="mb-2 flex space-x-2 md:flex-grow-0 md:flex-shrink-0 md:w-20 md:mb-0 md:p-2 md:text-center md:space-x-0">
        <div className="flex-initial font-bold md:hidden">Quantity:</div>
        <div className="flex-1">{props.item.quantity}</div>
      </div>

      <div className="mb-2 md:flex-1 md:mb-0 md:p-2">
        <div className="block">
          {props.item.name} {props.item.variant && `(${props.item.variant})`}
        </div>

        {size(props.item.extras) > 0 && (
          <OrderItemExtras extras={props.item.extras} />
        )}
      </div>

      <div className="mb-2 md:flex-1 md:mb-0 md:p-2">{`${props.item.subcategory} (${props.item.category})`}</div>

      <div className="mb-2 flex space-x-2 md:flex-grow-0 md:flex-shrink-0 md:w-32 md:mb-0 md:p-2 md:text-center md:space-x-0">
        <div className="flex-initial font-bold md:hidden">Unit Price:</div>
        <div className="flex-1">{currency(props.item.price)}</div>
      </div>

      <div className="flex space-x-2 md:flex-grow-0 md:flex-shrink-0 md:w-32 md:p-2 md:text-center md:space-x-0">
        <div className="flex-initial font-bold md:hidden">Item Price:</div>
        <div className="flex-1">{currency(props.item.total)}</div>
      </div>
    </li>
  );
};

export default OrderItem;
