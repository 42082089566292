import { db } from "lib/firebase";
import { snapshotToData } from "models/helpers";

export const getByID = async (id) => {
  const ref = db.collection("venues").doc(id);

  return await getByRef(ref);
};

export const getByRef = async (ref) => {
  if (!ref) return null;

  const doc = await ref.get();

  return snapshotToData(doc);
};
