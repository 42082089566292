import React from "react";
import capitalize from "lodash/capitalize";
import kebabCase from "lodash/kebabCase";
import { AnimatePresence, motion } from "framer-motion";
import useStatusChange from "./useStatusChange";

const OrderStatusBadge = (props) => {
  const [ref, open, actions] = useStatusChange(props.orderRef);

  const status = kebabCase(props.status);

  return (
    <div ref={ref} className="relative">
      <motion.div
        className="relative self-center p-2 w-28 text-base text-white text-center rounded cursor-pointer select-none"
        initial={false}
        animate={{ backgroundColor: color(status) }}
        onClick={actions.handleToggle}
      >
        {capitalize(props.status)}
      </motion.div>

      <AnimatePresence>
        {open && (
          <motion.div
            className="absolute z-10 inset-y-0 my-auto top-full mt-2 w-full md:origin-right md:top-auto md:right-full md:mr-2 md:mt-0 md:w-40"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
          >
            <select
              className="input"
              name="status"
              value={status}
              onChange={actions.handleChange}
            >
              <option value="new">New</option>
              <option value="in-progress">In Progress</option>
              <option value="overdue">Overdue</option>
              <option value="completed">Completed</option>
            </select>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default React.memo(OrderStatusBadge);

// Status Colours
const color = (status) => {
  switch (status) {
    case "new":
      return "#FFB300";
    case "in-progress":
      return "#3B82F6";
    case "overdue":
      return "#EF4444";
    case "completed":
      return "#10B981";
    default:
      return "#9CA3AF";
  }
};
