import useCategories from "../use-categories";
import SubCategory from "../SubCategory";
import AddCategory from "../AddCategory";

const SubCategories = (props) => {
  const [categories, actions] = useCategories(props.categories);

  return (
    <>
      <div className="relative mb-2">
        {categories.map((category, index) => (
          <SubCategory
            key={category.id}
            category={category}
            index={index}
            parent={props.parent}
            onMove={actions.handleMove}
            onDelete={() => props.onDelete(category.id)}
            onView={() => props.onView(category.id)}
          />
        ))}
      </div>

      <AddCategory
        venue={props.venue}
        parentRef={props.parent.ref}
        count={categories.length}
      />
    </>
  );
};

export default SubCategories;
