import { useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AnimatePresence, motion } from "framer-motion";
import size from "lodash/size";
import useOnClickOutside from "lib/use-on-click-outside";
import ExportProductsCSV from "./ProductsCSV";
import ExportSummaryCSV from "./SummaryCSV";
import ExportSummaryPDF from "./SummaryPDF";

const Export = (props) => {
  const ref = useRef(null);

  const [open, setOpen] = useState(false);

  useOnClickOutside(ref, () => setOpen(false));

  const handleToggle = () => setOpen(!open);

  if (!props.orders || !size(props.orders) || !props.endDate) return null;

  return (
    <div className="relative">
      <button
        ref={ref}
        type="button"
        className="inline-block self-center py-2 px-4 text-base transition rounded focus:outline-none border border-purple-800 text-purple-800 hover:bg-purple-800 hover:text-white disabled:cursor-wait"
        onClick={handleToggle}
      >
        <FontAwesomeIcon icon="download" /> Export
      </button>

      <AnimatePresence>
        {open && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            className="absolute top-full mt-1 right-0 w-48 overflow-hidden border border-gray-300 rounded"
          >
            <ul>
              <li className="hover:bg-gray-100">
                <ExportProductsCSV {...props} />
              </li>
              <li className="border-t border-gray-300 hover:bg-gray-100">
                <ExportSummaryCSV {...props} />
              </li>
              <li className="border-t border-gray-300 hover:bg-gray-100">
                <ExportSummaryPDF {...props} />
              </li>
            </ul>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Export;
