/**
 * Create a Product
 *
 * @param {DocumentReference} venueRef Venue Reference
 * @param {object} data New Product Data
 */
export const create = async (venueRef, data) => {
  // Turn category ID into a Document Reference
  data.category = venueRef.collection("categories").doc(data.category);

  await venueRef.collection("products").add(data);
};
