import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Loader(props) {
  const [show, setShow] = useState(!props.wait);

  useEffect(() => {
    const waitTimer = setTimeout(() => setShow(true), props.wait);

    return () => clearTimeout(waitTimer);
  }, [props.wait]);

  if (!show) return null;

  return (
    <div className="flex space-x-2 p-1">
      <FontAwesomeIcon icon="spinner" size="lg" spin />
      <span>Loading Orders</span>
    </div>
  );
}
