import groupBy from "lodash/groupBy";
import round from "lodash/round";
import sortBy from "lodash/sortBy";
import meanBy from "lodash/meanBy";
import reduce from "lodash/reduce";
import sumBy from "lodash/sumBy";
import { currency, number_format } from "lib/helpers";

export const getItemsFromOrders = (orders) => {
  let items = orders.reduce(
    (items, order) => (order.items ? [...items, ...order.items] : items),
    []
  );

  items = items.map((item) => ({
    ...item,
    category: !item.category ? "Uncategorised" : item.category,
    subcategory: !item.subcategory ? "Uncategorised" : item.subcategory,
  }));

  return sortBy(items, ["category", "subcategory"]);
};

export const sumProductsFromOrderItems = (items) => {
  return items.reduce((acc, curr) => {
    const exists = acc.find((a) => a.id === curr.id);

    if (exists) {
      return acc.map((product) => {
        return curr.id === product.id
          ? {
              ...product,
              quantity: round(product.quantity + curr.quantity),
              total: round(product.total + curr.total, 2),
            }
          : product;
      });
    }

    return [
      ...acc,
      {
        id: curr.id,
        name: curr.name,
        category: curr.category,
        subcategory: curr.subcategory,
        quantity: curr.quantity,
        total: curr.total,
      },
    ];
  }, []);
};

export const groupProductsByCategories = (products) => {
  // Group products by category
  let categories = groupBy(products, (p) => p.category);

  // Group categories by subcategory
  Object.keys(categories).forEach((key) => {
    categories[key] = groupBy(categories[key], (p) => p.subcategory);
  });

  return categories;
};

export const getReportSummary = (orders, format = true) => {
  let totals = {
    orders: orders.length,
    sales: totalSales(orders),
    order_items: totalOrderItems(orders),
    average_order_value: averageOrderValue(orders),
    tips: totalOrderTips(orders),
    revenue: totalRevenue(orders),
  };

  if (format) {
    totals.orders = number_format(totals.orders);
    totals.order_items = number_format(totals.order_items);
    totals.sales = currency(totals.sales);
    totals.average_order_value = currency(totals.average_order_value);
    totals.tips = currency(totals.tips);
    totals.revenue = currency(totals.revenue);
  }

  return totals;
};

const averageOrderValue = (orders) => {
  if (!orders.length) return 0;
  const mean = meanBy(orders, (order) =>
    order.subtotal ? order.subtotal : order.total
  );

  return round(mean, 2);
};

const totalSales = (orders) => {
  return reduce(
    orders,
    (sum, order) => {
      const amount = order.subtotal ? order.subtotal : order.total;
      return round(sum + amount, 2);
    },
    0
  );
};

const totalRevenue = (orders) => {
  return reduce(
    orders,
    (sum, order) => {
      let amount = order.subtotal
        ? order.total
        : round(order.total + (order.tip || 0), 2);

      amount = isNaN(amount) ? 0 : amount;

      return round(sum + amount, 2);
    },
    0
  );
};

const totalOrderItems = (orders) => {
  return sumBy(orders, (order) => (order.items ? order.items.length : 0));
};

const totalOrderTips = (orders) => {
  return sumBy(orders, (order) => order.tip);
};
