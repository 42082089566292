import Toggle from "components/Forms/Toggle";
import Textarea from "components/Forms/Textarea";

const Internal = (props) => {
  return (
    <fieldset className="space-y-4 mb-4">
      <Toggle
        label={props.fields.inactive ? "Inactive" : "Active"}
        name="inactive"
        checked={props.fields.inactive}
        onChange={props.actions.handleChange}
      />

      <Textarea
        label="Notes"
        name="notes"
        value={props.fields.notes}
        onChange={props.actions.handleChange}
      />
    </fieldset>
  );
};

export default Internal;
