import { BrowserRouter as Router } from "react-router-dom";
// import { DndProvider } from "react-dnd-multi-backend";
// import HTML5toTouch from "react-dnd-multi-backend/dist/esm/HTML5toTouch"; // or any other pipeline
// import { HTML5toTouch } from "rdndmb-html5-to-touch";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import Toast from "lib/toast";
import "lib/icons";
import NotificationProvider from "lib/NotificationContext";
import UserProvider from "lib/UserContext";
import Layout from "components/Layout";
import Routes from "./Routes";

function App() {
  return (
    <NotificationProvider>
      <UserProvider>
        <DndProvider backend={HTML5Backend}>
          <Router>
            <Layout>
              <Routes />

              <Toast />
            </Layout>
          </Router>
        </DndProvider>
      </UserProvider>
    </NotificationProvider>
  );
}

export default App;
