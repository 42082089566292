import { currency } from "lib/helpers";
import { v4 as uuidv4 } from "uuid";

const OrderItemExtras = (props) => {
  return (
    <div className="mt-1">
      <h4 className="text-sm font-semibold">Extras</h4>

      {props.extras.map((extra) => (
        <div key={uuidv4()} className="block text-sm">
          + {extra.name}
          {extra.price > 0 && (
            <span className="ml-1">(+{currency(extra.price)})</span>
          )}
        </div>
      ))}
    </div>
  );
};

export default OrderItemExtras;
