import { NavLink } from "react-router-dom";
import { useSignOut, useUser, useVenue } from "lib/UserContext";
import { isAdmin, isOwner } from "lib/helpers";
import OrderBadge from "./OrderBadge";

function Menu() {
  const signOut = useSignOut();
  const user = useUser();
  const venue = useVenue();

  return (
    <nav className="flex-grow overflow-scroll mb-2">
      <ul>
        {isAdmin(user) && (
          <li className="mb-2">
            <NavLink
              to="/venues"
              className="block py-2 px-4 rounded-lg transition hover:bg-purple-600 hover:text-white"
              activeClassName="bg-purple-600 text-white"
            >
              Venues
            </NavLink>
          </li>
        )}
        <li className="mb-2">
          <NavLink
            to="/orders"
            className="block relative py-2 px-4 rounded-lg transition hover:bg-purple-600 hover:text-white"
            activeClassName="bg-purple-600 text-white"
          >
            Orders
            <OrderBadge />
          </NavLink>
        </li>
        {isOwner(user) && (
          <li className="mb-2">
            <NavLink
              to="/menu"
              className="block py-2 px-4 rounded-lg transition hover:bg-purple-600 hover:text-white"
              activeClassName="bg-purple-600 text-white"
            >
              Menu
            </NavLink>
          </li>
        )}
        <li className="mb-2">
          <NavLink
            to="/tables"
            className="block py-2 px-4 rounded-lg hover:bg-purple-600 hover:text-white transition"
            activeClassName="bg-purple-600 text-white"
          >
            Tables
          </NavLink>
        </li>
        {isOwner(user) && venue?.takeaway && (
          <li className="mb-2">
            <NavLink
              to="/takeaway"
              className="block py-2 px-4 rounded-lg transition hover:bg-purple-600 hover:text-white"
              activeClassName="bg-purple-600 text-white"
            >
              Takeaway Info
            </NavLink>
          </li>
        )}

        {isOwner(user) && (
          <li className="mb-2">
            <NavLink
              to="/reports"
              className="block py-2 px-4 rounded-lg transition hover:bg-purple-600 hover:text-white"
              activeClassName="bg-purple-600 text-white"
            >
              Reports
            </NavLink>
          </li>
        )}
        <li className="mb-2">
          <button
            type="button"
            onClick={signOut}
            className="block w-full text-left py-2 px-4 rounded-lg hover:bg-purple-600 hover:text-white transition focus:outline-none"
          >
            Logout
          </button>
        </li>
      </ul>
    </nav>
  );
}

export default Menu;
