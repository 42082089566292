import merge from "lodash/fp/merge";
import useForm from "lib/use-form";

export default function useTakeawayForm(initialState) {
  const [fields, loading, errors, actions] = useForm(
    merge(defaultState, initialState),
    {},
    formValidation
  );

  return [fields, loading, errors, actions];
}

const defaultState = {
  delivery: {
    enabled: true,
    startTime: "",
    endTime: "",
    cost: 0,
    freeThreshold: 0,
  },
  collection: {
    enabled: true,
    startTime: "",
    endTime: "",
  },
};

const formValidation = {};
