import React, { useState, useEffect, useContext } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { auth } from "lib/firebase";
import PageLoader from "components/PageLoader";
import { getByID as getUserByID } from "models/users/read";
import {
  getByID as getVenueByID,
  getByRef as getVenueByRef,
} from "models/venues/read";

const UserContext = React.createContext();

const Provider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [venue, setVenue] = useState(null);
  const [loading, setLoading] = useState(true);

  // Auth Check
  useEffect(() => {
    const unsubscribeAuthStateListener = auth.onAuthStateChanged(
      async (auth) => {
        if (auth) {
          const user = await getUserByID(auth.uid);
          const venue = await getVenueByRef(user.venue);

          setUser(user);
          setVenue(venue);
        } else {
          setUser(null);
          setVenue(null);
        }

        setLoading(false);
      }
    );

    return () => {
      unsubscribeAuthStateListener();
    };
  }, []);

  const signInWithEmailAndPassword = (email, password) => {
    return auth.signInWithEmailAndPassword(email, password);
  };

  const signOut = () => auth.signOut();

  return (
    <UserContext.Provider
      value={{ user, venue, signInWithEmailAndPassword, signOut, setVenue }}
    >
      <AnimatePresence exitBeforeEnter>
        <motion.div
          key={loading ? "userLoading" : "userLoaded"}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
        >
          {loading ? <PageLoader full /> : children}
        </motion.div>
      </AnimatePresence>
    </UserContext.Provider>
  );
};

export const Consumer = UserContext.Consumer;

export const useUser = () => {
  const { user } = useContext(UserContext);

  return user;
};

export const useVenue = () => {
  const { venue } = useContext(UserContext);

  return venue;
};

export const useSignIn = () => {
  const { signInWithEmailAndPassword } = useContext(UserContext);
  return signInWithEmailAndPassword;
};

export const useSignOut = () => {
  const { signOut } = useContext(UserContext);
  return signOut;
};

export const useSetVenue = () => {
  const { setVenue } = useContext(UserContext);

  return async (id) => {
    const venue = !id ? null : await getVenueByID(id);

    setVenue(venue);
  };
};

export default Provider;
