import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import map from "lodash/map";
import uniq from "lodash/uniq";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import formatDistanceStrict from "date-fns/formatDistanceStrict";
import OrderStatusBadge from "components/OrderStatusBadge";
import OrderPrinting from "components/OrderPrinting";
import { useMemo } from "react";
import OrderPrintingPassPrnt from "components/OrderPrintingPassPrnt";
import useTimer from "lib/use-timer";

const Order = ({ order, payments }) => {
  const contents = useMemo(
    () => uniq(map(order.items, "category")).sort(),
    [order.items]
  );

  return (
    <motion.div
      layout
      className="mb-2 p-2 bg-gray-100 border-l-2 border-purple-600 lg:flex lg:items-center lg:p-0 lg:text-sm xl:text-base"
    >
      <div className="mb-2 flex items-center space-x-2 lg:block lg:space-x-0 lg:flex-initial lg:flex-shrink-0 lg:w-20 lg:mb-0 lg:p-2">
        <div className="flex-initial font-bold lg:hidden">Order:</div>
        <div className="flex-1">#{order.id}</div>
      </div>

      <div className="mb-2 flex items-center space-x-2 lg:block lg:space-x-0 lg:flex-initial lg:flex-shrink-0 lg:w-40 lg:mb-0 lg:p-2">
        <div className="flex-initial font-bold lg:hidden">Time:</div>
        <div className="flex-1">
          <OrderTimestamp date={order.date} />
        </div>
      </div>

      <div className="mb-2 flex items-center space-x-2 lg:block lg:space-x-0 lg:flex-initial lg:flex-shrink-0 lg:w-16 lg:mb-0 lg:p-2">
        <div className="flex-initial font-bold lg:hidden">Table:</div>
        <div className="flex-1">{order.table}</div>
      </div>

      {payments && (
        <div className="mb-2 flex items-center space-x-2 lg:block lg:space-x-0 lg:flex-initial lg:flex-shrink-0 lg:w-16 lg:mb-0 lg:p-2">
          <div className="flex-initial font-bold lg:hidden">Paid:</div>
          <div className="flex-1">
            {order.payment ? (
              <FontAwesomeIcon icon="check" className="text-green-500" />
            ) : (
              <FontAwesomeIcon icon="times" className="text-red-500" />
            )}
          </div>
        </div>
      )}

      <div className="mb-2 flex items-center space-x-2 lg:block lg:space-x-0 lg:flex-auto lg:mb-0 lg:p-2">
        <div className="flex-initial font-bold lg:hidden">Contents:</div>
        <div className="flex-1">{contents.join(", ")}</div>
      </div>

      <div className="flex space-x-2 items-center lg:flex-initial lg:flex-shrink-0 lg:p-2 lg:ml-auto">
        <OrderStatusBadge orderRef={order.ref} status={order.status} />

        <Link to={`/orders/view/${order.doc_id}`} className="button-gray">
          <FontAwesomeIcon icon="eye" className="text-sm mr-1" /> View
        </Link>

        <OrderPrinting order={order} />
        <OrderPrintingPassPrnt order={order} />
      </div>
    </motion.div>
  );
};

export default Order;

const OrderTimestamp = (props) => {
  const timestamp = useTimer();

  return formatDistanceStrict(props.date, timestamp, {
    addSuffix: true,
    roundingMethod: "floor",
  });
};
