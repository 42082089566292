import { NavLink } from "react-router-dom";
import logo from "images/logo.svg";

const Logo = () => (
  <NavLink
    to="/"
    className="flex flex-shrink-0 items-center w-16 h-16 mx-auto mb-8 rounded-lg bg-purple-600 bg-gradient-to-r from-indigo-700 to-purple-600"
  >
    <img className="h-3/4 m-auto" src={logo} alt="Table Service App" />
  </NavLink>
);

export default Logo;
