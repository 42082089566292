import { useHistory, useLocation } from "react-router";
import { useVenue } from "lib/UserContext";
import { create } from "models/products/create";
import ProductForm from "components/Forms/Product";

const CreateProduct = () => {
  const history = useHistory();
  const location = useLocation();
  const venue = useVenue();

  const category = location.state && location.state.category;

  const handleSubmit = async (data) => {
    try {
      const { category } = data;

      await create(venue.ref, data);

      history.push(`/menu/${category}`);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <h1 className="text-2xl font-semibold mb-4">Create Product</h1>

      {!venue ? (
        <p>Select a venue</p>
      ) : (
        <ProductForm
          venue={venue}
          initialState={{ category }}
          onSubmit={handleSubmit}
          back={category ? `/menu/${category}` : "/menu"}
        />
      )}
    </>
  );
};

export default CreateProduct;
