import toNumber from "lodash/toNumber";
import firebase from "lib/firebase";

export const formatGeoPoint = (location) => {
  const lat = toNumber(location._lat);
  const long = toNumber(location._long);

  return new firebase.firestore.GeoPoint(lat, long);
};

export const snapshotToData = (snapshot, idField = "id", refField = "ref") => {
  if (!snapshot.exists) return null;

  let data = snapshot.data();

  data = transform(data);

  data[idField] = snapshot.id;
  data[refField] = snapshot.ref;

  return data;
};

export const transform = (data) => {
  for (const [key, value] of Object.entries(data)) {
    // Convert Firestore Timestamp to JavaScript Date Object
    if (value instanceof firebase.firestore.Timestamp) {
      data[key] = value.toDate();
    }

    if (key === "price") data[key] = toNumber(value).toFixed(2);
  }

  return data;
};
