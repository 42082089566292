/**
 * Initialise Firebase
 */

import firebase from "firebase/app";
import "firebase/analytics";

import "firebase/firestore";
import "firebase/auth";
import "firebase/functions";
import "firebase/storage";

let config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

if (!firebase.apps.length) firebase.initializeApp(config);

if (window.location.hostname === "192.168.1.137") {
  firebase.functions().useEmulator("192.168.1.137", 5001);
}

export const db = firebase.firestore();
export const auth = firebase.auth();
export const functions = firebase.functions();
export const storage = firebase.storage();

export default firebase;
