import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNotification } from "lib/NotificationContext";

const Notifications = () => {
  const { enabled, toggleNotifications } = useNotification();

  return (
    <div className="mb-2">
      <button
        type="button"
        className={enabled ? "button-purple" : "button"}
        onClick={toggleNotifications}
      >
        <FontAwesomeIcon icon={enabled ? "bell" : "bell-slash"} />
      </button>
    </div>
  );
};

export default Notifications;
