import isEmpty from "lodash/isEmpty";
import isFinite from "lodash/isFinite";
import toNumber from "lodash/toNumber";
import inRange from "lodash/inRange";

export const required = (value, fieldName = "This field") => {
  if (isEmpty(value)) return `${fieldName} is required`;

  return null;
};

export const minLength = (value, min, fieldName = "This field") => {
  if (isEmpty(value)) return `${fieldName} is required`;

  if (value.trim().length < min) {
    return `${fieldName} must be at least ${min} characters long`;
  }

  return null;
};

export const number = (value, fieldName = "This field") => {
  if (!isFinite(toNumber(value))) return `${fieldName} is a required number`;

  return null;
};

export const date = (value, fieldName = "This field") => {
  if (value instanceof Date === false) return `${fieldName} is required`;

  return null;
};

export const range = (value, min, max, fieldName = "This field") => {
  if (value === "") return `${fieldName} is required`;

  if (!isFinite(toNumber(value))) return `${fieldName} is a required number`;

  if (!inRange(toNumber(value), min, max)) {
    return `${fieldName} must be a number between ${min} and ${max}`;
  }

  return null;
};

export const email = (value, fieldName = "Email") => {
  if (!validateEmail(value)) return `${fieldName} is required`;

  return null;
};

function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}
