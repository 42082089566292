import { useEffect, useState } from "react";

const useTimer = (duration = 1000) => {
  const [timestamp, setTimestamp] = useState(new Date().getTime());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimestamp(new Date().getTime());
    }, duration);

    return () => clearTimeout(timer);
  }, [duration]);

  return timestamp;
};

export default useTimer;
