import { useEffect, useRef, useState } from "react";
import trim from "lodash/trim";

const ProductName = (props) => {
  const ref = useRef(null);

  const [edit, setEdit] = useState(false);
  const [name, setName] = useState(props.product.name);

  useEffect(() => {
    if (edit) ref.current.select();
  }, [edit, ref]);

  const handleToggle = () => setEdit(true);

  const handleUpdate = async (e) => {
    e.preventDefault();

    const name = ref.current.value;

    if (trim(name).length > 0) {
      await props.product.ref.update({ name });
      setName(name);
    }

    setEdit(false);
  };

  if (edit) {
    return (
      <form onSubmit={handleUpdate}>
        <input
          ref={ref}
          type="text"
          className="block w-full bg-transparent focus:outline-none"
          defaultValue={name}
          onBlur={handleUpdate}
          autoComplete="off"
        />
      </form>
    );
  }

  return (
    <h3 className="hover:cursor-pointer" onClick={handleToggle}>
      {name}
    </h3>
  );
};

export default ProductName;
