/**
 * Move an Order to a new collection
 *
 * @param {DocumentReference} doc Document to move
 * @param {CollectionReference} ref Collection to move to
 *
 * @returns {DocumentReference} newRef New Order Reference
 */
export default async function move(docRef, ref) {
  if (!docRef) return null;

  // Get Order
  let order = await docRef.get();

  // Create new document
  const newRef = await ref.doc(order.id).set(order.data());

  // Delete old document
  await docRef.delete();

  return newRef;
}
