import clsx from "clsx";
import startOfDay from "date-fns/startOfDay";
import startOfWeek from "date-fns/startOfWeek";
import startOfMonth from "date-fns/startOfMonth";
import endOfDay from "date-fns/endOfDay";
import endOfWeek from "date-fns/endOfWeek";
import endOfMonth from "date-fns/endOfMonth";
import isEqual from "date-fns/isEqual";

export const TodayButton = (props) => {
  const now = new Date();
  const range = [startOfDay(now), endOfDay(now)];

  const handleChange = () => props.onClick(range);

  const isActive =
    isEqual(props.startDate, range[0]) &&
    (!props.endDate || isEqual(props.endDate, range[1]));

  return (
    <Button onClick={handleChange} active={isActive}>
      Today
    </Button>
  );
};

export const ThisWeekButton = (props) => {
  const now = new Date();
  const range = [startOfWeek(now), endOfWeek(now)];

  const handleChange = () => props.onClick(range);

  const isActive =
    isEqual(props.startDate, range[0]) && isEqual(props.endDate, range[1]);

  return (
    <Button onClick={handleChange} active={isActive}>
      This Week
    </Button>
  );
};

export const ThisMonthButton = (props) => {
  const now = new Date();
  const range = [startOfMonth(now), endOfMonth(now)];

  const handleChange = () => props.onClick(range);

  const isActive =
    isEqual(props.startDate, range[0]) && isEqual(props.endDate, range[1]);

  return (
    <Button onClick={handleChange} active={isActive}>
      This Month
    </Button>
  );
};

const Button = (props) => (
  <button
    type="button"
    onClick={props.onClick}
    className={clsx(
      "inline-block self-center py-2 px-3 text-base transition focus:outline-none",
      props.active
        ? "bg-purple-800 text-white"
        : "bg-purple-100 text-purple-800"
    )}
  >
    {props.children}
  </button>
);
