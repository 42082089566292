import { useEffect, useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";
import CategoryName from "../CategoryName";
import Toggle from "components/Forms/Toggle";

const Category = (props) => {
  const ref = useRef(null);
  const mounted = useRef(null);

  // Update Firestore when order changes
  useEffect(() => {
    const updateFirestoreOrder = async () => {
      await props.category.ref.update({ order: props.index });
    };

    if (mounted.current !== null && mounted.current !== props.index) {
      updateFirestoreOrder();
    }

    mounted.current = props.index;
  }, [props.category.ref, props.index]);

  const [, drop] = useDrop({
    accept: "category",
    hover(item, monitor) {
      if (!ref.current) return;

      const dragIndex = item.index;
      const hoverIndex = props.index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) return;

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get horizontal middle
      const hoverMiddleX =
        (hoverBoundingRect.right - hoverBoundingRect.left) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the left
      const hoverClientX = clientOffset.x - hoverBoundingRect.left;

      // Dragging rightwards
      if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
        return;
      }

      // Dragging leftwards
      if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
        return;
      }

      // Time to actually perform the action
      props.onMove(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: "category",
    item: () => {
      return { id: props.category.id, index: props.index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  const isActive = props.category.hasOwnProperty("active")
    ? props.category.active
    : true;

  return (
    <motion.section
      ref={ref}
      layout
      transition={{ duration: 0.2 }}
      className={`p-2 rounded bg-gray-100 shadow ${
        isDragging && "opacity-50"
      } ${!isActive && "opacity-50"}`}
    >
      <header className="flex items-center mb-2">
        <div className="flex-1">
          <CategoryName category={props.category} />
        </div>

        <div className="flex-initial align-top">
          <Toggle
            name="active"
            checked={isActive}
            onChange={props.onToggle}
            small
          />
        </div>

        <div className="flex-initial">
          <button
            type="button"
            className="flex items-center justify-center w-8 h-8 text-sm text-gray-400 rounded transition hover:text-gray-700 hover:bg-gray-200 focus:outline-none"
            onClick={props.onDelete}
          >
            <FontAwesomeIcon icon="times" />
          </button>
        </div>
      </header>

      {props.children}
    </motion.section>
  );
};

export default Category;
