import { CSVLink } from "react-csv";
import format from "date-fns/format";
import { useVenue } from "lib/UserContext";
import { getReportSummary } from "../../helpers";

const ExportSummaryCSV = (props) => {
  const venue = useVenue();

  const totals = getReportSummary(props.orders);

  return (
    <CSVLink
      filename={getFileName(venue.name, props.startDate, props.endDate)}
      headers={getHeaders()}
      data={[totals]}
      className="block p-2"
    >
      Summary CSV
    </CSVLink>
  );
};

export default ExportSummaryCSV;

function getFileName(name, start, end) {
  const pattern = "d MMM yyyy";

  start = format(start, pattern);
  end = format(end, pattern);

  return `${name} - Summary (${start} - ${end}).csv`;
}

function getHeaders() {
  return [
    { label: "Total Orders", key: "orders" },
    { label: "Total Order Items", key: "order_items" },
    { label: "Average Order Value", key: "average_order_value" },
    { label: "Total Sales", key: "sales" },
    { label: "Total Tips", key: "tips" },
    { label: "Total Revenue", key: "revenue" },
  ];
}
