import { Link, useParams, useHistory } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import sortBy from "lodash/sortBy";
import {
  useCollectionData,
  useDocumentDataOnce,
} from "react-firebase-hooks/firestore";
import { useVenue } from "lib/UserContext";
import ProductsList from "components/ProductsList";
import Toggle from "components/Forms/Toggle";

const ViewCategory = () => {
  const venue = useVenue();
  const { id } = useParams();
  const history = useHistory();

  const categoryRef = !venue
    ? null
    : venue.ref.collection("categories").doc(id);

  const [category] = useDocumentDataOnce(categoryRef, {
    idField: "id",
    refField: "ref",
  });

  let [products] = useCollectionData(
    venue
      ? venue.ref.collection("products").where("category", "==", categoryRef)
      : null,
    {
      idField: "id",
      refField: "ref",
    }
  );

  products = sortBy(products, ["order"]);

  if (!categoryRef) history.push("/menu");

  const someProductsActive = products.some((p) => p.active);

  const handleActiveChange = async () => {
    for (const product of products) {
      await product.ref.update({ active: !someProductsActive });
    }
  };

  return (
    <AnimatePresence>
      {category && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.1 }}
          className="fixed z-20 inset-0 overflow-y-auto flex items-center p-4"
          role="dialog"
        >
          {/* Overlay */}
          <Link
            to="/menu"
            className="block fixed z-10 inset-0 bg-black opacity-50"
            aria-hidden="true"
          />

          {/* Modal */}
          <div className="relative z-20 m-auto max-w-2xl w-full flex flex-col bg-gray-100 rounded text-left overflow-hidden shadow-xl">
            <div className="flex-1 w-full p-4">
              <div className="flex align-middle justify-between mb-4">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  {category.name}
                </h3>

                <Toggle
                  label={someProductsActive ? "Turn Off" : "Turn On"}
                  checked={someProductsActive}
                  onChange={handleActiveChange}
                  small
                />
              </div>

              <AnimatePresence>
                {products && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.2 }}
                  >
                    <ProductsList category={category} products={products} />
                  </motion.div>
                )}
              </AnimatePresence>
            </div>

            <div className="flex w-full justify-between px-4 py-2">
              <Link
                to={{
                  pathname: "/product/create",
                  state: { category: category.id },
                }}
                className="button-purple"
              >
                <FontAwesomeIcon className="mr-1" icon="plus" /> Add Product
              </Link>

              <Link
                to="/menu"
                className="button border border-gray-300 hover:bg-gray-300 focus:outline-none"
              >
                Close
              </Link>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default ViewCategory;
