import { useState } from "react";
import isEmpty from "lodash/isEmpty";
import filter from "lodash/filter";

const useTableSearch = (all) => {
  const [search, setSearch] = useState("");

  // Filter results
  const tables = filter(
    all,
    (row) =>
      isEmpty(search) ||
      row.number.toLowerCase().includes(search.toLowerCase()) ||
      row.description.toLowerCase().includes(search.toLowerCase())
  );

  return [tables, search, setSearch];
};

export default useTableSearch;
