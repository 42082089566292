import get from "lodash/get";
import Input from "components/Forms/Input";
import Toggle from "components/Forms/Toggle";

const Collection = (props) => {
  return (
    <>
      <div className="mb-4">
        <Toggle
          label="Enabled"
          name="collection.enabled"
          checked={props.fields.collection.enabled}
          onChange={props.actions.handleChange}
        />
      </div>

      <fieldset className="space-y-4 mb-4">
        <legend className="block w-full text-lg font-semibold">
          Collection Timeframe
        </legend>

        <div className="flex items-end gap-4">
          <div className="w-20">
            <Input
              type="number"
              id="startTime"
              name="collection.startTime"
              value={props.fields.collection.startTime}
              onChange={props.actions.handleChange}
              onBlur={props.actions.handleBlur}
              error={get(props.errors, "collection.startTime")}
            />
          </div>

          <div className="flex-none py-3">TO</div>

          <div className="w-20">
            <Input
              type="number"
              id="endTime"
              name="collection.endTime"
              value={props.fields.collection.endTime}
              onChange={props.actions.handleChange}
              onBlur={props.actions.handleBlur}
              error={get(props.errors, "collection.endTime")}
            />
          </div>

          <div className="flex-none py-3">MINUTES</div>
        </div>
      </fieldset>
    </>
  );
};

export default Collection;
