import { useState } from "react";
import { toast } from "react-toastify";
import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import capitalize from "lodash/capitalize";
import { useVenue } from "lib/UserContext";
import Button from "components/Forms/Button";

const initialState = { open: false, selected: [], loading: false };

const Multiple = (props) => {
  const venue = useVenue();

  const [state, setState] = useState(initialState);

  const openModal = () => setState({ ...state, open: true });

  const closeModal = () => setState(initialState);

  const handleSubmit = async () => {
    if (state.loading) return;

    try {
      setState({ ...state, loading: true });

      const doc = {
        order: props.order.ref,
        timestamp: new Date(),
      };

      for (const printer of state.selected) {
        await venue.ref.collection("print_queue").add({
          ...doc,
          location: printer.location,
        });

        toast(
          `Order #${props.order.id} has been sent to the ${printer.location} printer.`
        );
      }
    } catch (error) {
      console.error(error);
      toast.error("Error printing this order. Please try again.");
    } finally {
      setState(initialState);
    }
  };

  const handlePrint = async () => {
    if (!state.open) return openModal();

    await handleSubmit();
  };

  const handleSelect = (printer) => {
    if (state.selected.includes(printer)) {
      setState({
        ...state,
        selected: state.selected.filter((s) => s !== printer),
      });
    } else {
      setState({
        ...state,
        selected: [...state.selected, printer],
      });
    }
  };

  return (
    <>
      <button
        type="button"
        onClick={handlePrint}
        className="button-gray relative"
      >
        <FontAwesomeIcon icon="print" className="text-sm mr-1" /> Print
      </button>

      <SelectionModal
        {...state}
        printers={venue.printers}
        onSelect={handleSelect}
        onClose={closeModal}
        onConfirm={handlePrint}
      />
    </>
  );
};

export default Multiple;

const SelectionModal = (props) => {
  return (
    <AnimatePresence>
      {props.open && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.1 }}
          className="fixed z-20 inset-0 overflow-y-auto flex items-center p-4"
          role="dialog"
        >
          <div
            onClick={props.onClose}
            className="block fixed z-10 inset-0 bg-black opacity-50"
          />

          <div className="relative z-20 m-auto max-w-sm w-full p-4 flex flex-col bg-gray-100 rounded text-left overflow-hidden shadow-xl">
            <h2 className="mb-2 text-lg leading-none font-semibold">
              Select Printers
            </h2>

            <ul className="mb-2">
              {props.printers.map((printer, index) => {
                const isSelected = props.selected.includes(printer);

                return (
                  <li key={`printer-${index}`}>
                    <button
                      type="button"
                      onClick={() => props.onSelect(printer)}
                      className="w-full py-3 flex items-center space-x-4 focus:outline-none"
                    >
                      <div
                        className={clsx(
                          "flex items-center justify-center w-8 h-8 rounded transition border border-gray-300 text-black",
                          isSelected &&
                            "bg-purple-600 border-purple-600 text-white"
                        )}
                      >
                        {isSelected && <FontAwesomeIcon icon="check" />}
                      </div>
                      <span>{capitalize(printer.location)}</span>
                    </button>
                  </li>
                );
              })}
            </ul>

            <div className="flex justify-between space-x-4 pt-4 border-t border-gray-300">
              <Button
                type="button"
                loading={props.loading}
                disabled={props.loading || props.selected.length < 1}
                onClick={props.onConfirm}
                className="button-purple w-28"
              >
                Print
              </Button>

              <button
                type="button"
                onClick={props.onClose}
                className="button w-24 border border-gray-300 hover:bg-gray-300 focus:outline-none"
              >
                Cancel
              </button>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
