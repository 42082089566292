function Error404() {
  return (
    <div className="flex min-h-full items-center justify-center p-10">
      <div className="m-auto text-center">
        <h1 className="text-9xl font-semibold">404</h1>
        <p className="text-lg text-gray-500">Page Not Found</p>
      </div>
    </div>
  );
}

export default Error404;
