import get from "lodash/get";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Input from "components/Forms/Input";

const Variants = ({ variants, actions, errors }) => {
  return (
    <div className="p-4 mb-8 rounded bg-gray-100">
      <h2 className="mb-4 text-xl font-semibold">Variants</h2>

      {variants.map((variant, index) => (
        <div
          key={variant.id || `variant-${index}`}
          className="relative -mx-4 p-4 pb-0 border-t border-gray-300"
        >
          <button
            type="button"
            className="absolute top-0	right-0 w-8 h-8 text-sm transition hover:bg-gray-300 focus:outline-none"
            onClick={() => actions.deleteVariant(index)}
          >
            <FontAwesomeIcon icon="times" />
          </button>

          <div className="lg:grid lg:grid-cols-2 lg:gap-4">
            <Input
              label="Name"
              type="text"
              id={`variants.${index}.name`}
              name={`variants.[${index}].name`}
              data-validate="variants.name"
              value={variant.name}
              onChange={actions.handleChange}
              onBlur={actions.handleBlur}
              required
              error={get(errors, `variants.[${index}].name`)}
            />

            <Input
              label="Price"
              type="text"
              id={`variants.${index}.price`}
              name={`variants.[${index}].price`}
              data-validate="variants.price"
              value={variant.price}
              onChange={actions.handleChange}
              onBlur={actions.handleBlur}
              required
              error={get(errors, `variants.[${index}].price`)}
              placeholder="0.00"
            />
          </div>
        </div>
      ))}

      <button
        type="button"
        onClick={actions.addVariant}
        className="button-gray"
      >
        <FontAwesomeIcon className="mr-1" icon="plus" /> Add Variant
      </button>
    </div>
  );
};

export default Variants;
