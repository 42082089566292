import clsx from "clsx";
import { Link, useRouteMatch } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import QRCodeDownload from "./QRCodeDownload";

const VenueRow = (props) => {
  let { url } = useRouteMatch();

  const handleDelete = async () => {
    if (window.confirm("Are you sure you want to delete this venue?")) {
      await props.venue.ref.delete();
    }
  };

  return (
    <div
      className={clsx(
        "mb-2 p-2 bg-gray-100 border-l-2 border-purple-600 md:flex md:items-center",
        props.venue.inactive ? "opacity-50" : "opacity-100"
      )}
    >
      <div className="flex-1 mb-2 md:mb-0">
        <span className="inline-block font-bold md:hidden">Name:</span>{" "}
        {props.venue.name}
      </div>

      <div className="flex space-x-4 items-center md:flex-grow-0 md:flex-shrink-0">
        <Link
          to={`${url}/update/${props.venue.id}`}
          className="flex items-center justify-center w-8 h-8 rounded bg-purple-600 text-white leading-none transition hover:bg-purple-700"
        >
          <FontAwesomeIcon icon="pencil-alt" className="m-auto" />
        </Link>

        <a
          href={`https://venue.tableserviceapp.com/venues/${props.venue.id}`}
          target="_blank"
          rel="noreferrer"
          className="flex items-center justify-center w-8 h-8 rounded bg-blue-500 text-white leading-none transition hover:bg-blue-600"
        >
          <FontAwesomeIcon icon="external-link-alt" />
        </a>

        <QRCodeDownload venue={props.venue} />

        <button
          type="button"
          onClick={handleDelete}
          className="flex items-center justify-center w-8 h-8 rounded bg-red-500 text-white leading-none transition hover:bg-red-600 focus:outline-none"
        >
          <FontAwesomeIcon icon="trash-alt" className="m-auto" />
        </button>
      </div>
    </div>
  );
};

export default VenueRow;
