import clsx from "clsx";
import { motion } from "framer-motion";
import colors from "tailwindcss/colors";

const Toggle = ({ label, error, small, ...props }) => (
  <>
    <label className="inline-flex align-top items-center cursor-pointer">
      <input type="checkbox" {...props} className="opacity-0 w-0 h-0" />

      <motion.div
        animate={{
          backgroundColor: props.checked ? colors.blue[500] : colors.gray[300],
        }}
        initial={false}
        transition={{ duration: 0.2 }}
        className={clsx("flex-0 p-1 rounded-full", small ? "w-12" : "w-14")}
      >
        <motion.span
          animate={{ x: props.checked ? 24 : 0 }}
          initial={false}
          transition={{ duration: 0.2 }}
          className={clsx(
            "block rounded-full bg-white",
            small ? "w-4 h-4" : "w-6 h-6"
          )}
        />
      </motion.div>

      {label && <span className="flex-1 ml-2 select-none">{label}</span>}
    </label>

    {error && <span className="block text-sm text-red-500">{error}</span>}
  </>
);

export default Toggle;
