import { useState } from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import startOfDay from "date-fns/startOfDay";
import endOfDay from "date-fns/endOfDay";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useVenue } from "lib/UserContext";
import Filters from "./Filters";
import Export from "./Export";
import Summary from "./Summary";
import Tables from "./Tables";
import { AnimatePresence, motion } from "framer-motion";

const initialState = {
  startDate: startOfDay(new Date()),
  endDate: endOfDay(new Date()),
};

const Reports = () => {
  const venue = useVenue();

  const [state, setState] = useState(initialState);

  const [orders, loading] = useOrders(venue, state);

  return (
    <>
      <nav className="mb-4 space-y-2 lg:flex lg:space-y-0">
        <Filters state={state} setState={setState} />

        <div className="ml-auto">
          <Export orders={orders} {...state} />
        </div>
      </nav>

      <AnimatePresence exitBeforeEnter>
        <motion.div
          key={loading ? "loading" : !state.endDate ? "end-date" : "loaded"}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.1 }}
        >
          <ReportRender orders={orders} loading={loading} {...state} />
        </motion.div>
      </AnimatePresence>
    </>
  );
};

export default Reports;

function ReportRender(props) {
  if (!props.endDate) return <p>Select an end date</p>;

  if (props.loading) {
    return (
      <p className="space-x-2">
        <FontAwesomeIcon icon="spinner" spin />
        <span>Generating Report</span>
      </p>
    );
  }

  return (
    <>
      <Summary orders={props.orders} />

      <Tables orders={props.orders} />
    </>
  );
}

function useOrders(venue, state) {
  // Get current orders
  const [currentOrders = [], currentLoading] = useCollectionData(
    venue.ref
      .collection("current_orders")
      .where("date", ">", state.startDate)
      .where("date", "<", state.endDate)
  );

  // Get completed orders
  const [completedOrders = [], completedLoading] = useCollectionData(
    venue.ref
      .collection("orders")
      .where("date", ">", state.startDate)
      .where("date", "<", state.endDate)
  );

  let loading = currentLoading || completedLoading;

  let orders = currentOrders.concat(completedOrders);

  return [orders, loading];
}
