import { Route, Redirect } from "react-router-dom";
import { useUser } from "lib/UserContext";
import { isAdmin } from "lib/helpers";

function AdminRoute({ children, ...rest }) {
  const user = useUser();

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAdmin(user) ? (
          children
        ) : (
          <Redirect to={{ pathname: "/", state: { from: location } }} />
        )
      }
    />
  );
}

export default AdminRoute;
