import React, { useState, useEffect, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";

const Upload = (props) => {
  const [state, setState] = useState(props.value);

  const onDrop = useCallback(
    ([acceptedFile]) => {
      if (!acceptedFile) {
        toast.error("File type not supported.");
        return;
      }

      if (acceptedFile) {
        setState(URL.createObjectURL(acceptedFile));
        props.onChange({ target: { name: props.name, value: acceptedFile } });
      }
    },
    [props]
  );

  useEffect(
    () => () => {
      // Make sure to revoke the data uri to avoid memory leaks
      URL.revokeObjectURL(state);
    },
    [state]
  );

  const { getRootProps, getInputProps, isDragAccept, isDragReject } =
    useDropzone({
      accept: "image/*",
      multiple: false,
      onDrop,
    });

  return (
    <div
      {...getRootProps({
        className:
          "relative flex p-2 items-center justify-center h-40 text-lg text-gray-500 rounded border-2 border-dashed border-gray-300",
      })}
    >
      <input {...getInputProps({ className: "bg-gray-600" })} />

      {(isDragAccept || isDragReject) && (
        <div className="absolute inset-0 z-10 flex items-center justify-center leading-tight bg-gray-100">
          <p>
            {isDragAccept && "Drop image to replace"}
            {isDragReject && "File formatted not supported"}
          </p>
        </div>
      )}

      {state ? (
        <img
          className="block w-full h-full object-contain"
          src={state}
          alt=""
        />
      ) : (
        <p>Drag 'n' drop a file here, or click to select file</p>
      )}
    </div>
  );
};

export default Upload;
