import { useEffect, useRef, useState, useCallback } from "react";
import { useParams } from "react-router";
import { Link, useLocation } from "react-router-dom";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useVenue } from "lib/UserContext";
import { transform } from "models/helpers";
import OrderStatusBadge from "components/OrderStatusBadge";
import OrderDetails from "components/OrderDetails";
import OrderItems from "components/OrderItems";
import OrderPrinting from "components/OrderPrinting";
import OrderPrintingPassPrnt from "components/OrderPrintingPassPrnt";
import { getByID } from "models/orders/read";

export default function OrdersPage() {
  const { id } = useParams();
  const { search } = useLocation();
  const venue = useVenue();

  const order = useOrder(id);

  // Close the return back from passprnt (Star Printer callback)
  if (search.includes("passprnt_message")) window.close();

  if (!order) return null;

  return (
    <>
      <div className="flex items-center space-x-2 mb-4">
        <div className="flex-1">
          <h1 className="text-2xl font-semibold">Order #{order.id}</h1>
        </div>
        <div className="flex-initial">
          <OrderStatusBadge orderRef={order.ref} status={order.status} />
        </div>
      </div>

      <OrderDetails venue={venue} order={order} />

      <OrderItems order={order} />

      <div className="flex justify-between max-w-5xl">
        <Link
          to={order.status === "completed" ? "/orders/completed" : "/orders"}
          className="button border border-gray-300 hover:bg-gray-300 focus:outline-none"
        >
          <FontAwesomeIcon icon="arrow-left" className="text-sm mr-1" /> Back to
          Orders
        </Link>

        <OrderPrinting order={order} />
        <OrderPrintingPassPrnt order={order} />
      </div>
    </>
  );
}

function useOrder(id) {
  const status = useRef(null);

  const venue = useVenue();

  const [state, setState] = useState({ ref: null, loading: true });

  const fetchDocument = useCallback(async () => {
    let ref = await getByID(venue.ref, id);

    setState({ ref, loading: false });
  }, [venue, id]);

  const [order] = useDocumentData(state.ref, {
    idField: "doc_id",
    refField: "ref",
    transform,
  });

  useEffect(() => {
    if (venue) fetchDocument();
  }, [venue, id, fetchDocument]);

  useEffect(() => {
    if (status.current && status.current !== order?.status) {
      fetchDocument();
    }

    status.current = order?.status || null;
  }, [order, fetchDocument]);

  return order;
}
