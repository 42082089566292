import size from "lodash/size";
import { useVenue } from "lib/UserContext";
import Single from "./Single";
import Multiple from "./Multiple";

const OrderPrinting = (props) => {
  const venue = useVenue();

  const count = size(venue.printers);

  if (!venue.printing.cloudprnt || !count) return null;

  if (count < 2) return <Single order={props.order} />;

  return <Multiple order={props.order} />;
};

export default OrderPrinting;
