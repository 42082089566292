import { useState } from "react";
import { toast } from "react-toastify";
import get from "lodash/get";
import set from "lodash/fp/set";
import isArray from "lodash/isArray";
import isObject from "lodash/isObject";

const useForm = (initialFields, initialErrors, formValidation) => {
  const [fields, setFields] = useState(initialFields);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(initialErrors);

  /**
   * Validate a single field
   *
   * @param {string} name Field name
   * @param {string} value Field value
   */
  const validateField = (name, value) => {
    // Validate field
    const validation = get(formValidation, name);

    return validation ? validation(value, fields) : null;
  };

  /**
   * Validate entire form
   */
  const validateForm = () => {
    let errors = {};

    const validateFieldGroup = (fields, keys = [], name = null) => {
      for (const [key, value] of Object.entries(fields)) {
        if (isArray(value)) {
          value.forEach((v, i) => {
            validateFieldGroup(v, [...keys, key], [...keys, key, i]);
          });
        } else if (isObject(value)) {
          validateFieldGroup(value, [...keys, key]);
        } else {
          const keyPath = [...keys, key];
          const namePath = name ? [...name, key] : [...keys, key];

          const error = validateField(keyPath, value);

          if (error) errors = set(namePath, error, errors);
        }
      }
    };

    validateFieldGroup(fields);

    return errors;
  };

  /**
   * Handle blur event
   *
   * @param {object} e input event object
   */
  const handleBlur = (e) => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    const validateKey = target.dataset.validate || name;

    const error = validateField(validateKey, value);
    setErrors(set(name, error, errors));
  };

  /**
   * Handle input change
   *
   * @param {object} e input event object
   */
  const handleChange = (e) => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    const validateKey = target.dataset?.validate || name;

    setFields(set(name, value, fields));

    // Valid non text inputs on change
    if (["select", "radio", "checkbox"].indexOf(target.type) > -1) {
      const error = validateField(validateKey, value);
      setErrors(set(name, error, errors));
    }
  };

  /**
   * Handle form submission
   *
   * @param {object} e input event object
   */
  const handleSubmit = (e, callback) => {
    e.preventDefault();

    // Clear errors
    setErrors({});

    const errors = validateForm();

    if (!Object.values(errors).length) {
      setLoading(true);
      callback(fields);
    } else {
      setErrors(errors);
      displayErrors(errors);
    }
  };

  const displayErrors = (errors) => {
    if (!errors) return;

    Object.values(errors).map((value) => {
      if (isArray(value)) {
        return value.forEach(displayErrors);
      }

      if (isObject(value)) {
        return displayErrors(value);
      }

      return toast.error(value);
    });
  };

  const actions = {
    handleChange,
    handleBlur,
    handleSubmit,
    setFields,
    setErrors,
    setLoading,
  };

  return [fields, loading, errors, actions];
};

export default useForm;
