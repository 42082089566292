import { create } from "models/venues/create";
import VenueForm from "components/Forms/Venue";

function CreateVenue() {
  return (
    <>
      <h1 className="text-2xl font-semibold mb-4">Create Venue</h1>

      <VenueForm onSubmit={create} />
    </>
  );
}

export default CreateVenue;
