import { useEffect, useRef } from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { AnimatePresence, motion } from "framer-motion";
import isEqual from "lodash/isEqual";
import orderBy from "lodash/orderBy";
import { useVenue } from "lib/UserContext";
import { useNotification } from "lib/NotificationContext";

const OrderBadge = () => {
  const venue = useVenue();
  const { pushNotification } = useNotification();

  const lastOrder = useRef(null);
  const count = useRef(null);

  let [orders = [], loading] = useCollectionData(
    venue ? venue.ref.collection("current_orders") : null
  );

  // Order by date descending
  orders = orderBy(orders, ["date"], ["desc"]);

  // Push Notification when new order comes in
  useEffect(() => {
    if (count.current !== null && count.current < orders.length) {
      const order = orders[0];

      if (!isEqual(order, lastOrder.current)) {
        pushNotification(
          "New Order Received!",
          `Order #${order.id} for table number ${order.table}.`
        );
      }
    }

    count.current = loading ? null : orders.length;
    lastOrder.current = loading ? null : orders[0] || null;
  }, [orders, loading, pushNotification]);

  return (
    <AnimatePresence exitBeforeEnter>
      {orders && orders.length > 0 && (
        <motion.span
          key={`order-count-${venue.id}-${orders.length}`}
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          exit={{ scale: 0 }}
          transition={{ duration: 0.1 }}
          className="absolute inset-y-1.5 right-1 badge"
        >
          {orders.length}
        </motion.span>
      )}
    </AnimatePresence>
  );
};

export default OrderBadge;
