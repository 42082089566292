import clsx from "clsx";

const Input = ({ label, error, helpText, ...props }) => (
  <div>
    <label className="block mb-1" htmlFor={props.id}>
      {label}
    </label>

    <input className={clsx("input mb-1", error && "has-error")} {...props} />

    {helpText && (
      <span className="block text-sm text-gray-500">{helpText}</span>
    )}
    {error && <span className="block text-sm text-red-500">{error}</span>}
  </div>
);

export default Input;
