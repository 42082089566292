import { v4 as uuidv4 } from "uuid";

const Select = ({ label, options, ...props }) => (
  <div>
    <label className="block mb-1" htmlFor={props.id}>
      {label}
    </label>

    <select className="input" {...props}>
      {options &&
        options.map((option) => (
          <option key={uuidv4()} value={option.value}>
            {option.label}
          </option>
        ))}
    </select>
  </div>
);

export default Select;
