import { useEffect, useReducer, useMemo } from "react";
import { snapshotToData } from "models/helpers";

const perPage = 50;

const initialState = {
  view: "list",
  limit: perPage,
  orders: [],
  loaded: false,
  hasMore: false,
};

export default function useOrdersTables(venue, completed) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    dispatch({ type: "RESET" });
  }, [venue, completed]);

  let query = useMemo(() => {
    return venue.ref
      .collection(completed ? "orders" : "current_orders")
      .orderBy("date", "desc");
  }, [venue, completed]);

  useEffect(() => {
    let fn = query.limit(state.limit);

    let unsubscribe = fn.onSnapshot((snapshot) => {
      dispatch({ type: "LOADED", snapshot });
    });

    return () => unsubscribe();
  }, [query, state.limit]);

  // Handle change of list/grid view
  const handleViewChange = (view) => dispatch({ type: "VIEW-CHANGE", view });

  // Handle load more trigger
  const handleLoadMore = () => dispatch({ type: "LOAD-MORE" });

  return [state, { handleViewChange, handleLoadMore }];
}

function reducer(state, action) {
  switch (action.type) {
    case "LOADED":
      let orders = [...state.orders];
      let isAdding = false;

      action.snapshot.docChanges().forEach((change) => {
        const order = snapshotToData(change.doc, "doc_id");

        if (change.type === "added") {
          isAdding = true;
          orders = addItem(order, orders);
        } else if (change.type === "modified") {
          orders = updateItem(order, orders);
        } else if (change.type === "removed") {
          orders = deleteItem(order, orders);
        }
      });

      let end = orders.length < state.limit;

      return {
        ...state,
        loaded: true,
        hasMore: isAdding ? !end : state.hasMore,
        orders,
      };

    case "LOAD-MORE":
      return {
        ...state,
        limit: state.limit + perPage,
      };

    case "RESET":
      if (!state.loaded) return state;

      return {
        ...state,
        limit: perPage,
        orders: [],
        loaded: false,
        hasMore: false,
      };

    case "VIEW-CHANGE":
      return {
        ...state,
        view: action.view,
      };

    default:
      throw new Error();
  }
}

function addItem(doc, items) {
  const exists = items.find((item) => item.doc_id === doc.doc_id);
  return exists ? items : [...items, doc];
}

function updateItem(doc, items) {
  return items.map((item) => (item.doc_id === doc.doc_id ? doc : item));
}

function deleteItem(doc, items) {
  return items.filter((item) => item.doc_id !== doc.doc_id);
}
