import { useDocumentDataOnce } from "react-firebase-hooks/firestore";
import { Redirect, useLocation, useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { useVenue } from "lib/UserContext";
import { update } from "models/products/update";
import { transform } from "models/helpers";
import ProductForm from "components/Forms/Product";

const UpdateProduct = () => {
  const { id } = useParams();
  const history = useHistory();
  const location = useLocation();
  const venue = useVenue();

  const category = location.state && location.state.category;

  const productRef = !venue ? null : venue.ref.collection("products").doc(id);

  const [product, loading] = useDocumentDataOnce(productRef, { transform });

  const handleSubmit = async (data) => {
    try {
      const { category } = data;

      await update(venue.ref, productRef, data);

      history.push(`/menu/${category}`);
    } catch (error) {
      console.error(error);
    }
  };

  if (loading) return null;

  if (!product && !loading) return <Redirect to="/menu" />;

  return (
    <>
      <h1 className="text-2xl font-semibold mb-4">Update Product</h1>

      {!venue ? (
        <p>Select a venue</p>
      ) : (
        <ProductForm
          venue={venue}
          initialState={{ ...product, category: product.category.id }}
          onSubmit={handleSubmit}
          back={category ? `/menu/${category}` : "/menu"}
        />
      )}
    </>
  );
};

export default UpdateProduct;
