import { Switch, Route } from "react-router-dom";

import AdminRoute from "components/Routes/AdminRoute";
import OwnerRoute from "components/Routes/OwnerRoute";
import PrivateRoute from "components/Routes/PrivateRoute";

import Login from "./pages/Login";
import Home from "./pages/Home";

import Venues from "./pages/venues";
import CreateVenue from "./pages/venues/create";
import UpdateVenue from "./pages/venues/update";

import Menu from "./pages/menu";
import ViewCategory from "./pages/category/view";

import CreateProduct from "./pages/product/create";
import UpdateProduct from "./pages/product/update";

import ViewOrder from "./pages/orders/view";
import Orders from "./pages/orders";

import Tables from "./pages/tables";

import Reports from "./pages/reports";

import Takeaway from "./pages/takeaway";

import Error404 from "./pages/Error404";

const Routes = () => {
  return (
    <>
      <Switch>
        <Route path="/login">
          <Login />
        </Route>

        <PrivateRoute exact path="/">
          <Home />
        </PrivateRoute>

        {/* Venues */}
        <AdminRoute path="/venues/create">
          <CreateVenue />
        </AdminRoute>
        <AdminRoute path="/venues/update/:id">
          <UpdateVenue />
        </AdminRoute>
        <AdminRoute path="/venues">
          <Venues />
        </AdminRoute>

        {/* Menu */}
        <OwnerRoute path="/menu">
          <Menu />
        </OwnerRoute>

        {/* Products */}
        <OwnerRoute path="/product/create">
          <CreateProduct />
        </OwnerRoute>
        <OwnerRoute path="/product/:id">
          <UpdateProduct />
        </OwnerRoute>

        {/* Orders */}
        <PrivateRoute path="/orders/view/:id">
          <ViewOrder />
        </PrivateRoute>
        <PrivateRoute path="/orders">
          <Orders />
        </PrivateRoute>

        {/* Tables */}
        <PrivateRoute path="/tables">
          <Tables />
        </PrivateRoute>

        {/* Menu */}
        <OwnerRoute path="/reports">
          <Reports />
        </OwnerRoute>

        {/* Takeaway */}
        <OwnerRoute path="/takeaway">
          <Takeaway />
        </OwnerRoute>

        <PrivateRoute>
          <Error404 />
        </PrivateRoute>
      </Switch>

      <OwnerRoute path="/menu/:id">
        <ViewCategory />
      </OwnerRoute>
    </>
  );
};

export default Routes;
