/**
 * Update a Product
 *
 * @param {DocumentReference} venueRef Venue Reference
 * @param {DocumentReference} ref Product Reference
 * @param {object} data New Product Data
 */
export const update = async (venueRef, ref, data) => {
  // Turn category ID into a Document Reference
  data.category = venueRef.collection("categories").doc(data.category);

  // Update Product Document
  await ref.update(data);
};
