import { useState } from "react";
import clsx from "clsx";
import { useUser } from "lib/UserContext";

import Logo from "./Logo";
import VenueSelector from "./VenueSelector";
import Menu from "./Menu";
import User from "./User";
import { MobileOverlay, MobileMenuToggle } from "./Mobile";
import { isAdmin } from "lib/helpers";
import Notifications from "./Notifications";

function Sidebar() {
  const user = useUser();

  const [open, setOpen] = useState(false);

  return (
    <>
      <aside
        className={clsx(
          "w-3/4 h-full fixed z-20 top-0 left-0 p-4 transform transition-transform duration-300 ease-in-out bg-gray-50 sm:transform-none sm:transition-none sm:relative sm:flex-initial sm:w-48 sm:flex sm:flex-shrink-0 sm:flex-col sm:-translate-x-0 sm:border-r xl:w-60",
          open ? "-translate-x-0" : "-translate-x-full"
        )}
      >
        <MobileMenuToggle open={open} toggleMenu={() => setOpen(!open)} />

        <Logo />

        {isAdmin(user) && <VenueSelector />}

        <Menu />

        <Notifications />

        <User />
      </aside>

      {/* Mobile Background Overlay */}
      <MobileOverlay open={open} closeMenu={() => setOpen(false)} />
    </>
  );
}

export default Sidebar;
