import { updateSimple } from "models/venues/update";
import { useVenue } from "lib/UserContext";
import Takeaway from "components/Takeaway";

export default function TakeawayPage() {
  const venue = useVenue();

  return (
    <>
      <h1 className="text-2xl font-semibold mb-4">Takeaway Info</h1>

      {!venue ? (
        <p>Select a venue</p>
      ) : !venue.takeaway ? (
        <p>This venue is not marked as takeaway</p>
      ) : (
        <Takeaway
          initialState={{
            id: venue.id,
            delivery: venue?.delivery,
            collection: venue?.collection,
          }}
          onSubmit={updateSimple}
        />
      )}
    </>
  );
}
