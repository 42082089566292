import clsx from "clsx";

export const MobileMenuToggle = (props) => (
  <button
    type="button"
    className="absolute top-0 left-full p-4 sm:hidden focus:outline-none text-white focus:bg-purple-700 bg-purple-600"
    onClick={props.toggleMenu}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      {props.open ? (
        <path
          fill="currentColor"
          d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"
        />
      ) : (
        <path
          fill="currentColor"
          d="M24 6h-24v-4h24v4zm0 4h-24v4h24v-4zm0 8h-24v4h24v-4z"
        />
      )}
    </svg>
  </button>
);

export const MobileOverlay = (props) => (
  <div
    className={clsx(
      "absolute z-10 inset-0 bg-black bg-opacity-60 transition",
      props.open ? "opacity-100 visible" : "opacity-0 invisible"
    )}
    aria-hidden="true"
    onClick={props.closeMenu}
  ></div>
);
