import get from "lodash/get";
import capitalize from "lodash/capitalize";
import Input from "components/Forms/Input";
import Toggle from "components/Forms/Toggle";

const OpeningTimes = (props) => {
  return (
    <fieldset className="mb-4 space-y-4">
      {Object.keys(props.fields.times).map((day) => {
        return (
          <div key={`times-${day}`} className="pb-4 border-b border-gray-300">
            <legend className="block w-full mb-1 text-lg font-semibold">
              {capitalize(day)}
            </legend>

            <Toggle
              label={props.fields.times[day].open ? "Open" : "Closed"}
              id={`times.${day}.open`}
              name={`times.${day}.open`}
              checked={props.fields.times[day].open}
              onChange={props.actions.handleChange}
            />

            {props.fields.times[day].open && (
              <div className="grid mt-2 md:grid-cols-3 gap-4">
                <Input
                  label="Opening"
                  type="time"
                  id={`times.${day}.opening`}
                  name={`times.${day}.opening`}
                  value={props.fields.times[day].opening}
                  onChange={props.actions.handleChange}
                  onBlur={props.actions.handleBlur}
                  required
                  error={get(props.errors, `times.${day}.opening`)}
                />

                <Input
                  label="Closing"
                  type="time"
                  id={`times.${day}.closing`}
                  name={`times.${day}.closing`}
                  value={props.fields.times[day].closing}
                  onChange={props.actions.handleChange}
                  onBlur={props.actions.handleBlur}
                  required
                  error={get(props.errors, `times.${day}.closing`)}
                />

                <Input
                  label="Last Orders"
                  type="time"
                  id={`times.${day}.last_orders`}
                  name={`times.${day}.last_orders`}
                  value={props.fields.times[day].last_orders}
                  onChange={props.actions.handleChange}
                  onBlur={props.actions.handleBlur}
                  required
                  error={get(props.errors, `times.${day}.last_orders`)}
                />
              </div>
            )}
          </div>
        );
      })}
    </fieldset>
  );
};

export default OpeningTimes;
