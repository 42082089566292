/**
 * Retrieve a single Order based upon it's document ID
 * This method checks both current_orders & orders
 *
 * @param {DocumentReference} ref Venue Reference
 * @returns DocumentReference docRef
 */
export const getByID = async (ref, id) => {
  let docRef, doc;

  try {
    // Check Current Orders
    docRef = ref.collection("current_orders").doc(id);
    doc = await docRef.get();
    if (doc.exists) return docRef;

    // Check Completed Orders
    docRef = ref.collection("orders").doc(id);
    doc = await docRef.get();
    if (doc.exists) return docRef;

    return null;
  } catch (error) {
    console.error(error);
    return null;
  }
};
