import get from "lodash/get";
import Input from "components/Forms/Input";
import Toggle from "components/Forms/Toggle";

const Delivery = (props) => {
  return (
    <>
      <div className="mb-4">
        <Toggle
          label="Enabled"
          name="delivery.enabled"
          checked={props.fields.delivery.enabled}
          onChange={props.actions.handleChange}
        />
      </div>

      <fieldset className="space-y-4 mb-4">
        <legend className="block w-full text-lg font-semibold">
          Delivery Timeframe
        </legend>

        <div className="flex items-end gap-4">
          <div className="w-20">
            <Input
              type="number"
              id="startTime"
              name="delivery.startTime"
              value={props.fields.delivery.startTime}
              onChange={props.actions.handleChange}
              onBlur={props.actions.handleBlur}
              error={get(props.errors, "delivery.startTime")}
            />
          </div>

          <div className="flex-none py-3">TO</div>

          <div className="w-20">
            <Input
              type="number"
              id="endTime"
              name="delivery.endTime"
              value={props.fields.delivery.endTime}
              onChange={props.actions.handleChange}
              onBlur={props.actions.handleBlur}
              error={get(props.errors, "delivery.endTime")}
            />
          </div>

          <div className="flex-none py-3">MINUTES</div>
        </div>
      </fieldset>

      <fieldset className="space-y-4 mb-4">
        <legend className="block w-full text-lg font-semibold">
          Delivery Costs
        </legend>

        <div className="grid grid-cols-2 gap-4">
          <Input
            label="Delivery Cost"
            type="number"
            id="cost"
            name="delivery.cost"
            value={props.fields.delivery.cost}
            onChange={props.actions.handleChange}
            onBlur={props.actions.handleBlur}
            error={get(props.errors, "delivery.cost")}
          />

          <Input
            label="Free Delivery Over"
            type="number"
            id="freeThreshold"
            name="delivery.freeThreshold"
            value={props.fields.delivery.freeThreshold}
            onChange={props.actions.handleChange}
            onBlur={props.actions.handleBlur}
            error={get(props.errors, "delivery.freeThreshold")}
          />

          <Input
            label="Minimum Order Amount"
            type="number"
            id="minOrder"
            name="delivery.minOrder"
            value={props.fields.delivery.minOrder}
            onChange={props.actions.handleChange}
            onBlur={props.actions.handleBlur}
            error={get(props.errors, "delivery.minOrder")}
          />
        </div>
      </fieldset>
    </>
  );
};

export default Delivery;
