import { db, functions } from "lib/firebase";
import { formatGeoPoint } from "models/helpers";

/**
 * Create a Venue
 *
 * @param {object} data New Venue Data
 */
export const create = async (data) => {
  const { users, ...venue } = data;

  venue.location = formatGeoPoint(venue.location);

  const ref = await db.collection("venues").add(venue);

  // Update User/Auth Management
  await functions.httpsCallable("venueUsers")({ users, venue: ref.id });
};
