import get from "lodash/get";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Input from "components/Forms/Input";
import Select from "components/Forms/Select";

const Users = (props) => {
  return (
    <fieldset>
      <legend className="block w-full mb-3 text-lg font-semibold">
        User Accounts
      </legend>

      <div className="space-y-4 mb-4">
        {props.fields.users.map((user, index) => (
          <div
            key={user.id || `user-${index}`}
            className="relative p-4 rounded bg-gray-100"
          >
            <button
              type="button"
              className="absolute top-0	right-0 w-8 h-8 text-sm transition hover:bg-gray-300 focus:outline-none"
              onClick={() => props.actions.deleteUser(index)}
            >
              <FontAwesomeIcon icon="times" />
            </button>

            <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-4">
              <Input
                label="Name"
                type="text"
                id={`users.${index}.name`}
                name={`users.[${index}].name`}
                data-validate="users.name"
                value={user.name}
                onChange={props.actions.handleChange}
                onBlur={props.actions.handleBlur}
                required
                error={get(props.errors, `users.[${index}].name`)}
              />

              <Select
                label="Role"
                id={`users.${index}.role`}
                name={`users.[${index}].role`}
                data-validate="users.role"
                value={user.role}
                onChange={props.actions.handleChange}
                options={[
                  { value: "owner", label: "Owner" },
                  { value: "staff", label: "Staff" },
                ]}
                required
                error={get(props.errors, `users.[${index}].role`)}
              />

              <Input
                label="Email"
                type="email"
                id={`users.${index}.email`}
                name={`users.[${index}].email`}
                data-validate="users.email"
                value={user.email}
                onChange={props.actions.handleChange}
                onBlur={props.actions.handleBlur}
                required
                error={get(props.errors, `users.[${index}].email`)}
              />

              <Input
                label="Password"
                type="password"
                id={`users.${index}.password`}
                name={`users.[${index}].password`}
                data-validate="users.password"
                value={user.password}
                onChange={props.actions.handleChange}
                onBlur={props.actions.handleBlur}
                required
                error={get(props.errors, `users.[${index}].password`)}
                placeholder={user.id ? "Leave blank to not change" : ""}
              />
            </div>
          </div>
        ))}

        <button
          type="button"
          onClick={props.actions.addUser}
          className="button-gray"
        >
          <FontAwesomeIcon className="mr-1" icon="plus" /> Add User
        </button>
      </div>
    </fieldset>
  );
};

export default Users;
