import get from "lodash/get";
import Input from "components/Forms/Input";
import Toggle from "components/Forms/Toggle";
import Textarea from "components/Forms/Textarea";

const Payments = (props) => {
  return (
    <fieldset className="space-y-4 mb-4">
      <legend className="block w-full text-lg font-semibold">
        Payment Methods
      </legend>

      <div>
        <Toggle
          label="Accept Tips"
          name="tips"
          checked={props.fields.tips}
          onChange={props.actions.handleChange}
        />
      </div>

      <div>
        <Toggle
          label="Pay at Table"
          name="payments.cash.enabled"
          checked={props.fields.payments.cash.enabled}
          onChange={props.actions.handleChange}
        />
      </div>

      <div>
        <Toggle
          label="Enable Stripe"
          name="payments.stripe.enabled"
          checked={props.fields.payments.stripe?.enabled}
          onChange={props.actions.handleChange}
        />
      </div>

      {props.fields.payments.stripe?.enabled && (
        <div className="grid gap-4 p-4 rounded bg-gray-100">
          <Input
            label="Publishable API Key"
            type="text"
            id="payments.stripe.publishable"
            name="payments.stripe.publishable"
            value={props.fields.payments.stripe.publishable}
            onChange={props.actions.handleChange}
            onBlur={props.actions.handleBlur}
            required
            error={get(props.errors, "payments.stripe.publishable")}
          />

          <Input
            label="Secret/Restricted API Key"
            type="text"
            id="payments.stripe.secret"
            name="payments.stripe.secret"
            value={props.fields.payments.stripe.secret}
            onChange={props.actions.handleChange}
            onBlur={props.actions.handleBlur}
            required
            error={get(props.errors, "payments.stripe.secret")}
          />
        </div>
      )}

      <div>
        <Toggle
          label="Enable Paymentsense"
          name="payments.paymentsense.enabled"
          checked={props.fields.payments.paymentsense?.enabled}
          onChange={props.actions.handleChange}
        />
      </div>

      {props.fields.payments.paymentsense?.enabled && (
        <div className="grid gap-4 p-4 rounded bg-gray-100">
          <Input
            label="Gateway Username"
            type="text"
            id="payments.paymentsense.gatewayUsername"
            name="payments.paymentsense.gatewayUsername"
            value={props.fields.payments.paymentsense.gatewayUsername}
            onChange={props.actions.handleChange}
            onBlur={props.actions.handleBlur}
            required
            error={get(props.errors, "payments.paymentsense.gatewayUsername")}
          />

          <Input
            label="Gateway Password"
            type="text"
            id="payments.paymentsense.gatewayPassword"
            name="payments.paymentsense.gatewayPassword"
            value={props.fields.payments.paymentsense.gatewayPassword}
            onChange={props.actions.handleChange}
            onBlur={props.actions.handleBlur}
            required
            error={get(props.errors, "payments.paymentsense.gatewayPassword")}
          />

          <Textarea
            label="JWT"
            id="payments.paymentsense.JWT"
            name="payments.paymentsense.JWT"
            value={props.fields.payments.paymentsense.JWT}
            onChange={props.actions.handleChange}
            onBlur={props.actions.handleBlur}
            required
            error={get(props.errors, "payments.paymentsense.JWT")}
          />
        </div>
      )}

      <div>
        <Toggle
          label="Enable Stripe Connect"
          name="payments.stripeConnect.enabled"
          checked={props.fields.payments.stripeConnect?.enabled}
          onChange={props.actions.handleChange}
        />
      </div>

      {props.fields.payments.stripeConnect?.enabled && (
        <div className="grid gap-4 p-4 rounded bg-gray-100">
          <Input
            label="Publishable API Key"
            type="text"
            id="payments.stripeConnect.publishable"
            helpText="Your Stripe Publishable API Key. Starts with pk_"
            name="payments.stripeConnect.publishable"
            value={props.fields.payments.stripeConnect.publishable}
            onChange={props.actions.handleChange}
            onBlur={props.actions.handleBlur}
            required
            error={get(props.errors, "payments.stripeConnect.publishable")}
          />

          <Input
            label="Secret API Key"
            type="text"
            id="payments.stripeConnect.secret"
            helpText="Your Stripe Secret API Key. Starts with sk_"
            name="payments.stripeConnect.secret"
            value={props.fields.payments.stripeConnect.secret}
            onChange={props.actions.handleChange}
            onBlur={props.actions.handleBlur}
            required
            error={get(props.errors, "payments.stripeConnect.secret")}
          />

          <Input
            label="Express Account ID"
            type="text"
            id="payments.stripeConnect.accountID"
            helpText="Connected Account ID. Starts with acct_"
            name="payments.stripeConnect.accountID"
            value={props.fields.payments.stripeConnect.accountID}
            onChange={props.actions.handleChange}
            onBlur={props.actions.handleBlur}
            required
            error={get(props.errors, "payments.stripeConnect.accountID")}
          />

          <div className="grid grid-cols-2 gap-4">
            <Input
              label="Fee Percentage"
              type="text"
              id="payments.stripeConnect.applicationFeePercent"
              name="payments.stripeConnect.applicationFeePercent"
              value={props.fields.payments.stripeConnect.applicationFeePercent}
              placeholder="1"
              onChange={props.actions.handleChange}
              onBlur={props.actions.handleBlur}
              required
              error={get(
                props.errors,
                "payments.stripeConnect.applicationFeePercent"
              )}
            />

            <Input
              label="Fee Fixed Amount (pence)"
              type="text"
              id="payments.stripeConnect.applicationFeeAmount"
              name="payments.stripeConnect.applicationFeeAmount"
              value={props.fields.payments.stripeConnect.applicationFeeAmount}
              placeholder="20"
              onChange={props.actions.handleChange}
              onBlur={props.actions.handleBlur}
              required
              error={get(
                props.errors,
                "payments.stripeConnect.applicationFeeAmount"
              )}
            />
          </div>
        </div>
      )}
    </fieldset>
  );
};

export default Payments;
