import isEmpty from "lodash/isEmpty";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Search = (props) => {
  return (
    <div className="flex space-x-2 items-center">
      <input
        id="search"
        className="block w-full max-w-lg p-2 border border-gray-400 rounded focus:border-black focus:outline-none"
        value={props.value}
        onChange={(e) => props.onChange(e.target.value)}
        placeholder="Search..."
        autoComplete="off"
      />

      {!isEmpty(props.value) && (
        <button
          type="button"
          onClick={() => props.onChange("")}
          className="flex items-center justify-center w-10 h-10 rounded bg-white text-gray-400 leading-none transition hover:bg-gray-100 hover:text-gray-700 focus:outline-none"
        >
          <FontAwesomeIcon icon="times" className="m-auto" />
        </button>
      )}
    </div>
  );
};

export default Search;
