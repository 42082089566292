import Categories from "./Categories";
import {
  getItemsFromOrders,
  sumProductsFromOrderItems,
  groupProductsByCategories,
} from "../helpers";

const Tables = (props) => {
  // Get all items
  const items = getItemsFromOrders(props.orders);

  // Accumulate products from items
  const products = sumProductsFromOrderItems(items);

  // Group product into categories -> subcategories
  const categories = groupProductsByCategories(products);

  if (!products.length) return <p>No Data Available</p>;

  return Object.entries(categories).map(([category, subcategories], i) => {
    return (
      <Categories
        key={`category-${i}`}
        name={category}
        subcategories={subcategories}
      />
    );
  });
};

export default Tables;
