import { Redirect, useParams } from "react-router";
import filter from "lodash/filter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  useCollectionDataOnce,
  useDocumentDataOnce,
} from "react-firebase-hooks/firestore";
import { db } from "lib/firebase";
import { update } from "models/venues/update";
import VenueForm from "components/Forms/Venue";

function UpdateVenue() {
  const { id } = useParams();

  const venueRef = db.collection("venues").doc(id);

  const [venue, loading] = useDocumentDataOnce(venueRef, { idField: "id" });

  let [users, loading2] = useCollectionDataOnce(
    db.collection("users").where("venue", "==", venueRef),
    { idField: "id" }
  );

  // Exclude admins
  users = filter(users, (u) => u.role !== "admin");
  // Remove Venue Ref & Set Password to blank
  users = users.map(({ venue, ...user }) => ({ ...user, password: "" }));

  if (loading || loading2) return null;

  if (!venue && !loading) return <Redirect to="/venues" />;

  const venueURL = `https://venue.tableserviceapp.com/venues/${venue.id}`;

  return (
    <>
      <h1 className="text-2xl font-semibold mb-4">
        Update Venue: {venue.name}
      </h1>

      <div className="p-2 mb-4 flex items-center space-x-2 max-w-xl text-sm rounded bg-gray-100">
        <input
          className="flex-1 block w-full p-2 rounded bg-white focus:outline-none"
          defaultValue={venueURL}
          onClick={(e) => e.target.select()}
        />

        <a
          href={venueURL}
          target="_blank"
          rel="noreferrer"
          className="w-9 h-9 leading-9 text-center text-black transition rounded hover:bg-gray-300"
        >
          <FontAwesomeIcon icon="external-link-alt" />
        </a>
      </div>

      <VenueForm initialState={{ ...venue, users }} onSubmit={update} />
    </>
  );
}

export default UpdateVenue;
