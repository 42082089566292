import Sidebar from "components/Layout/Sidebar";
import { useUser } from "lib/UserContext";

function Layout(props) {
  const user = useUser();

  if (!user) return props.children;

  return (
    <div className="flex absolute inset-0 w-full overflow-hidden">
      <Sidebar />

      <div className="relative h-full overflow-scroll flex-grow p-4 pt-16 sm:pt-4">
        {props.children}
      </div>
    </div>
  );
}

export default Layout;
