import { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AddCategory = (props) => {
  const ref = useRef(null);
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");

  const handleAddCategory = async (e) => {
    e.preventDefault();

    setOpen(false);

    await props.venue.ref.collection("categories").add({
      name: name,
      parent: props.parentRef || null,
      order: props.count,
    });

    setName("");
  };

  useEffect(() => {
    if (open) ref.current.focus();
  }, [open, ref]);

  const handleInputBlur = () => {
    if (!name.length) setOpen(false);
  };

  const buttonText = props.parentRef ? "Add Sub Category" : "Add Category";

  if (open) {
    return (
      <form onSubmit={handleAddCategory}>
        <input
          ref={ref}
          className="block w-full mb-2 py-1.5 px-2 border border-gray-400 rounded focus:border-black focus:outline-none"
          type="text"
          name="name"
          placeholder="Title"
          value={name}
          onChange={(e) => setName(e.target.value)}
          onBlur={handleInputBlur}
          autoComplete="off"
        />

        <button type="submit" className="button-purple">
          {buttonText}
        </button>
      </form>
    );
  }

  return (
    <button
      type="button"
      onClick={() => setOpen(true)}
      className="block w-full p-2.5 rounded text-left text-sm bg-gray-100 transition text-gray-600 hover:bg-gray-200 focus:outline-none"
    >
      <FontAwesomeIcon className="mr-1" icon="plus" /> {buttonText}
    </button>
  );
};

export default AddCategory;
