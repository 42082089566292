import get from "lodash/get";
import merge from "lodash/fp/merge";
import useForm from "lib/use-form";
import * as validate from "lib/validate";

const useVenueForm = (initialState) => {
  const [fields, loading, errors, actions] = useForm(
    merge(defaultState, initialState),
    {},
    formValidation
  );

  const addUser = () => {
    actions.setFields({
      ...fields,
      users: [...fields.users, defaultStateUser],
    });
  };

  const deleteUser = (index) => {
    actions.setFields({
      ...fields,
      users: fields.users.filter((_, i) => i !== index),
    });

    actions.setErrors({
      ...errors,
      users: errors.users ? errors.users.filter((_, i) => i !== index) : [],
    });
  };

  const addPrinter = () => {
    actions.setFields({
      ...fields,
      printers: [...fields.printers, defaultStatePrinter],
    });
  };

  const deletePrinter = (index) => {
    actions.setFields({
      ...fields,
      printers: fields.printers.filter((_, i) => i !== index),
    });

    actions.setErrors({
      ...errors,
      printers: errors.printers
        ? errors.printers.filter((_, i) => i !== index)
        : [],
    });
  };

  return [
    fields,
    loading,
    errors,
    { ...actions, addUser, deleteUser, addPrinter, deletePrinter },
  ];
};

export default useVenueForm;

const defaultStateUser = { name: "", email: "", password: "", role: "owner" };

const defaultStatePrinter = { location: "", automatic: false };

const defaultState = {
  name: "",
  address1: "",
  address2: "",
  town: "",
  city: "",
  postcode: "",
  location: { _lat: "", _long: "" },
  tips: false,
  payments: {
    cash: { enabled: true },
    stripe: { enabled: false, publishable: "", secret: "" },
    paymentsense: {
      enabled: false,
      gatewayUsername: "",
      gatewayPassword: "",
      JWT: "",
    },
  },
  printing: {
    cloudprnt: false,
    passprnt: false,
  },
  printers: [],
  users: [],
  notes: "",
  colors: {
    primary: "#FFDD19",
    accent: "#000000",
  },
  logo: "",
  show_address: true,
  times: {
    monday: {
      open: true,
      opening: "",
      closing: "",
      last_orders: "",
    },
    tuesday: {
      open: true,
      opening: "",
      closing: "",
      last_orders: "",
    },
    wednesday: {
      open: true,
      opening: "",
      closing: "",
      last_orders: "",
    },
    thursday: {
      open: true,
      opening: "",
      closing: "",
      last_orders: "",
    },
    friday: {
      open: true,
      opening: "",
      closing: "",
      last_orders: "",
    },
    saturday: {
      open: true,
      opening: "",
      closing: "",
      last_orders: "",
    },
    sunday: {
      open: true,
      opening: "",
      closing: "",
      last_orders: "",
    },
  },
  inactive: false,
  order_id: 1,
};

const formValidation = {
  name: (value) => validate.required(value, "Venue Name"),
  users: {
    name: (value) => validate.required(value, "User Name"),
    email: (value) => validate.email(value, "User Email"),
    role: (value) => validate.required(value, "User Role"),
  },
  payments: {
    stripe: {
      publishable: (value, fields) => {
        return get(fields, "payments.stripe.enabled", false)
          ? validate.required(value, "Stripe Publishable Key")
          : null;
      },
      secret: (value, fields) => {
        return get(fields, "payments.stripe.enabled", false)
          ? validate.required(value, "Stripe Secret/Restricted Key")
          : null;
      },
    },
    paymentsense: {
      gatewayUsername: (value, fields) => {
        return get(fields, "payments.paymentsense.enabled", false)
          ? validate.required(value, "Paymentsense Gateway Username")
          : null;
      },
      gatewayPassword: (value, fields) => {
        return get(fields, "payments.paymentsense.enabled", false)
          ? validate.required(value, "Paymentsense Gateway Password")
          : null;
      },
      JWT: (value, fields) => {
        return get(fields, "payments.paymentsense.enabled", false)
          ? validate.required(value, "Paymentsense JWT")
          : null;
      },
    },
  },
};
