import { useState } from "react";
import isEmpty from "lodash/isEmpty";
import filter from "lodash/filter";

const useVenueSearch = (all) => {
  const [search, setSearch] = useState("");
  const [inactive, showInactive] = useState(false);

  let venues = inactive ? all : filter(all, (v) => !v.inactive);

  // Filter results
  venues = filter(venues, (row) => {
    return (
      isEmpty(search) || row.name.toLowerCase().includes(search.toLowerCase())
    );
  });

  const handleSearch = (term) => setSearch(term);

  const handleInactiveToggle = () => showInactive(!inactive);

  return [venues, search, inactive, { handleSearch, handleInactiveToggle }];
};

export default useVenueSearch;
