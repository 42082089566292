import isObject from "lodash/isObject";
import { db, functions, storage } from "lib/firebase";
import { formatGeoPoint } from "models/helpers";

/**
 * Update a Venue
 *
 * @param {object} data New Venue Data
 */
export const update = async (data) => {
  const { id, users, ...venue } = data;

  const ref = db.collection("venues").doc(id);

  venue.location = formatGeoPoint(venue.location);

  // Update Venue Logo
  if (isObject(venue.logo)) {
    venue.logo = await updateLogo(id, venue.logo);
  }

  // Update Venue Document
  await ref.update(venue);

  // Update User/Auth Management
  await functions.httpsCallable("venueUsers")({ users, venue: ref.id });
};

// A quick copy of "update", but just for takeaway info
export const updateSimple = async (data) => {
  const { id, ...venue } = data;

  const ref = db.collection("venues").doc(id);

  // Update Venue Document
  await ref.update(venue);
};

async function updateLogo(id, file) {
  try {
    const storageRef = storage.ref();
    const ref = storageRef.child(`logos/${id}`);

    const snapshot = await ref.put(file);

    const path = await snapshot.ref.getDownloadURL();

    return path;
  } catch (error) {
    console.error(error);
    return null;
  }
}
