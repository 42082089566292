import { useEffect, useState } from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { db } from "lib/firebase";
import { useSetVenue, useVenue } from "lib/UserContext";

const VenueSelector = () => {
  const venue = useVenue();
  const setVenue = useSetVenue();

  const [value, setValue] = useState(venue ? venue.id : "");

  useEffect(() => {
    setValue(venue ? venue.id : "");
  }, [venue]);

  const [venues] = useCollectionData(db.collection("venues").orderBy("name"), {
    idField: "id",
  });

  const handleChange = (e) => {
    const id = e.target.value;

    setValue(id);
    setVenue(id);
  };

  return (
    <select value={value} onChange={handleChange} className="input mb-4">
      <option value="" disabled>
        Select a venue
      </option>

      {venues &&
        venues.map((venue) => (
          <option key={`venueSelector${venue.id}`} value={venue.id}>
            {venue.name}
          </option>
        ))}
    </select>
  );
};

export default VenueSelector;
