import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Button = ({ loading, children, ...props }) => (
  <button
    className="button-purple w-36"
    disabled={props.disabled || loading}
    {...props}
  >
    {loading ? <FontAwesomeIcon icon="spinner" size="lg" spin /> : children}
  </button>
);

export default Button;
