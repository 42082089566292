import { useState } from "react";
import QRCode from "qrcode";
import {
  usePDF,
  Font,
  Document,
  Page,
  View,
  Text,
  Image,
  StyleSheet,
} from "@react-pdf/renderer";
import { AnimatePresence, motion } from "framer-motion";
import kebabCase from "lodash/kebabCase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

Font.register({
  family: "Montserrat",
  fonts: [
    {
      src: "/fonts/Montserrat-Bold.ttf",
      fontWeight: 700,
    },
  ],
});

const styles = StyleSheet.create({
  body: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minWidth: "100%",
    minHeight: "100%",
    height: "100%",
    width: "100%",
  },
  background: {
    position: "absolute",
    display: "block",
    minWidth: "100%",
    minHeight: "100%",
    height: "100%",
    width: "100%",
  },
  content: {
    position: "relative",
    width: 220,
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  logo: {
    display: "block",
    width: 48,
    height: 48,
    marginBottom: 8,
  },
  heading: {
    fontFamily: "Montserrat",
    fontWeight: 700,
    fontSize: 26,
    lineHeight: 1,
    color: "white",
    marginBottom: 12,
  },
  qrCodeWrap: {
    position: "relative",
    display: "block",
    width: 90,
    height: 90,
    marginBottom: 12,
  },
  qrCode: {
    display: "block",
    width: 90,
    height: 90,
    backgroundColor: "white",
  },
  arrow: {
    position: "absolute",
    top: "50%",
    left: 100,
    marginTop: -20,
    display: "block",
    width: 61,
    height: 54,
  },
  text: {
    fontFamily: "Montserrat",
    fontWeight: 700,
    fontSize: 10,
    lineHeight: 1.3,
    color: "white",
  },
});

const QRCodeSticker = (props) => {
  return (
    <Document>
      <Page size="A4">
        <View style={styles.body}>
          <Image style={styles.background} src="/linear-background.png" />

          <View style={styles.content}>
            <Image style={styles.logo} src="/logo.png" />

            <Text style={styles.heading}>ORDER & PAY</Text>

            <View style={styles.qrCodeWrap}>
              <Image style={styles.qrCode} src={props.qrcode} />

              <Image style={styles.arrow} src="/scan-me.png" />
            </View>

            <Text style={styles.text}>
              No app required. {"\n"}
              Simply scan the QR code above.
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

const QRCodeDownloadButton = (props) => {
  const [instance] = usePDF({ document: <QRCodeSticker {...props} /> });

  const fileName = `${kebabCase(props.venue.name)}.pdf`;

  return (
    <a
      href={instance.url}
      download={fileName}
      className="flex items-center justify-center w-8 h-8 rounded bg-green-500 text-white leading-none transition hover:bg-green-600 focus:outline-none"
    >
      <FontAwesomeIcon icon="download" className="m-auto" />
    </a>
  );
};

const QRCodeDownload = (props) => {
  const [state, setState] = useState({
    qrcode: null,
    loading: false,
  });

  const handleQRCode = async () => {
    try {
      setState({ ...state, loading: true });

      const qrcode = await QRCode.toDataURL(
        `https://venue.tableserviceapp.com/venues/${props.venue.id}`,
        { width: 180 }
      );

      setState({ qrcode, loading: false });
    } catch (error) {
      console.error(error);

      setState({ ...state, loading: false });
    }
  };

  return (
    <AnimatePresence exitBeforeEnter initial={false}>
      <motion.div
        key={state.qrcode ? "loaded" : "not-loaded"}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.2 }}
      >
        {state.qrcode ? (
          <QRCodeDownloadButton {...props} qrcode={state.qrcode} />
        ) : (
          <button
            type="button"
            onClick={handleQRCode}
            disabled={state.loading}
            className="flex items-center justify-center w-8 h-8 rounded bg-green-500 text-white leading-none transition hover:bg-green-600 focus:outline-none disabled:cursor-wait disabled:bg-gray-200"
          >
            {state.loading ? (
              <FontAwesomeIcon icon="spinner" size="lg" spin />
            ) : (
              <FontAwesomeIcon icon="qrcode" className="m-auto" />
            )}
          </button>
        )}
      </motion.div>
    </AnimatePresence>
  );
};

export default QRCodeDownload;
