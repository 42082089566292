import { Route, Redirect } from "react-router-dom";
import { useUser } from "lib/UserContext";

function PrivateRoute({ children, ...rest }) {
  const user = useUser();

  return (
    <Route
      {...rest}
      render={({ location }) =>
        user ? (
          children
        ) : (
          <Redirect to={{ pathname: "/login", state: { from: location } }} />
        )
      }
    />
  );
}

export default PrivateRoute;
