import React, { useContext, useState } from "react";

const NotificationContext = React.createContext();

const Provider = ({ children }) => {
  const soundEffect = new Audio();

  const [sound, enableSound] = useState(false);
  const [alert, enableAlert] = useState(false);

  const enabled = sound || alert;

  const toggleNotifications = () => {
    soundEffect.play().catch(console.error);

    const newState = !enabled;

    enableSound(newState);
    enableAlert(newState);

    if (newState && "Notification" in window) Notification.requestPermission();
  };

  const pushNotification = (title = "Table Service", body) => {
    if (!enabled) return;

    playSound();
    sendAlert(title, body);
  };

  const playSound = () => {
    soundEffect.src = "/notification.mp3";
    soundEffect.play().catch(console.error);
  };

  const sendAlert = (title, body) => {
    if ("Notification" in window && Notification.permission === "granted") {
      const notification = new Notification(title, {
        body,
        icon: "/notification-icon-128.png",
      });

      notification.onclick = () => window.focus();

      return;
    }
  };

  return (
    <NotificationContext.Provider
      value={{
        enabled,
        toggleNotifications,
        pushNotification,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export const Consumer = NotificationContext.Consumer;

export const useNotification = () => useContext(NotificationContext);

export default Provider;
