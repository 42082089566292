import { useVenue } from "lib/UserContext";
import { getReportSummary } from "../helpers";

const Summary = (props) => {
  const venue = useVenue();

  if (!props.orders) return null;

  const totals = getReportSummary(props.orders);

  return (
    <div className="mb-4 pb-4 border-b border-gray-300">
      <h2 className="text-xl font-semibold mb-2">Report Summary</h2>

      <table className="table-auto mt-2 border-collapse">
        <tbody>
          <tr className="border-b-2 border-white bg-gray-100">
            <th className="p-2 text-left font-bold">Total Orders:</th>
            <td className="p-2 text-right">{totals.orders}</td>
          </tr>
          <tr className="border-b-2 border-white bg-gray-100">
            <th className="p-2 text-left font-bold">Total Order Items:</th>
            <td className="p-2 text-right">{totals.order_items}</td>
          </tr>
          <tr className="border-b-2 border-white bg-gray-100">
            <th className="p-2 text-left font-bold">Average Order Value:</th>
            <td className="p-2 text-right">{totals.average_order_value}</td>
          </tr>

          <tr className="border-b-2 border-white bg-gray-100">
            <th className="p-2 text-left font-bold">Total Sales:</th>
            <td className="p-2 text-right">{totals.sales}</td>
          </tr>

          {venue.tips && (
            <tr className="border-b-2 border-white bg-gray-100">
              <th className="p-2 text-left font-bold">Total Tips:</th>
              <td className="p-2 text-right">{totals.tips}</td>
            </tr>
          )}

          <tr className="border-b-2 border-white bg-gray-100">
            <th className="p-2 text-left font-bold">Total Revenue:</th>
            <td className="p-2 text-right">{totals.revenue}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Summary;
