import { useRef, useState } from "react";
import useOnClickOutside from "lib/use-on-click-outside";
import move from "models/orders/move";
import { useVenue } from "lib/UserContext";

const useStatusChange = (orderRef) => {
  const venue = useVenue();
  const ref = useRef(null);

  const [open, setOpen] = useState(false);

  useOnClickOutside(ref, () => setOpen(false));

  const handleToggle = () => setOpen(!open);

  const handleChange = async (e) => {
    const status = e.target.value;

    const collection = orderRef.parent.id;

    await orderRef.update({ status });

    // Move completed order to "orders"
    if (status === "completed" && collection !== "orders") {
      await move(orderRef, venue.ref.collection("orders"));
    }
    // Move non-completed orders to "current_orders"
    else if (status !== "completed" && collection !== "current_orders") {
      await move(orderRef, venue.ref.collection("current_orders"));
    }

    if (ref.current) setOpen(false);
  };

  const actions = { handleToggle, handleChange };

  return [ref, open, actions];
};

export default useStatusChange;
