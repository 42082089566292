import { CSVLink } from "react-csv";
import format from "date-fns/format";
import { useVenue } from "lib/UserContext";
import { getItemsFromOrders, sumProductsFromOrderItems } from "../../helpers";
import { currency } from "lib/helpers";

const ExportProductsCSV = (props) => {
  const venue = useVenue();

  return (
    <CSVLink
      filename={getFileName(venue.name, props.startDate, props.endDate)}
      headers={getHeaders()}
      data={getProductData(props.orders)}
      className="block p-2"
    >
      Products CSV
    </CSVLink>
  );
};

export default ExportProductsCSV;

function getFileName(name, start, end) {
  const pattern = "d MMM yyyy";

  start = format(start, pattern);
  end = format(end, pattern);

  return `${name} - Products (${start} - ${end}).csv`;
}

function getHeaders() {
  return [
    { label: "Product Name", key: "name" },
    { label: "Category", key: "category" },
    { label: "Subcategory", key: "subcategory" },
    { label: "Quantity Sold", key: "quantity" },
    { label: "Total Sales", key: "total" },
  ];
}

function getProductData(orders) {
  // Get all items
  const items = getItemsFromOrders(orders);

  // Accumulate products from items
  let products = sumProductsFromOrderItems(items);

  return products.map((product) => ({
    ...product,
    total: currency(product.total),
  }));
}
