import { Redirect } from "react-router";
import { useUser } from "lib/UserContext";
import { isAdmin } from "lib/helpers";

function Home() {
  const user = useUser();

  if (!isAdmin(user)) return <Redirect to="/orders" />;

  return <Redirect to="/venues" />;
}

export default Home;
