import { useVenue } from "lib/UserContext";
import MenuBuilder from "components/MenuBuilder";

function Menu() {
  const venue = useVenue();

  return (
    <>
      <h1 className="text-2xl font-semibold mb-4">Menu</h1>

      {!venue ? <p>Select a venue</p> : <MenuBuilder venue={venue} />}
    </>
  );
}

export default Menu;
