import { useVenue } from "lib/UserContext";
import { clone } from "models/products/clone";
import { deleteByRef } from "models/products/delete";
import Product from "./Product";
import useProducts from "./use-products";

const ProductsList = (props) => {
  const venue = useVenue();

  const [products, actions] = useProducts(props.products);

  const handleDelete = async (product) => {
    if (window.confirm("Are you sure you want to delete this product?")) {
      await deleteByRef(product.ref);
    }
  };

  const handleClone = async (product) => {
    await clone(venue.ref, product.ref, props.products.length, true);
  };

  const handleActiveChange = async (product, active) => {
    await product.ref.update({ active: !active });
    actions.setState(
      products.map((p) => (p.id === product.id ? { ...p, active: !active } : p))
    );
  };

  return (
    <div className="relative">
      {products.map((product, index) => (
        <Product
          key={product.id}
          product={product}
          category={props.category}
          index={index}
          onMove={actions.handleMove}
          onDelete={() => handleDelete(product)}
          onClone={() => handleClone(product)}
          onActive={() => handleActiveChange(product, product.active)}
        />
      ))}
    </div>
  );
};

export default ProductsList;
