import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import firebase from "lib/firebase";
import { useSignIn, useUser } from "lib/UserContext";
import Input from "components/Forms/Input";
import Button from "components/Forms/Button";
import logo from "images/logo.svg";

function Login() {
  const history = useHistory();

  const user = useUser();
  const signInWithEmailAndPassword = useSignIn();

  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState({ email: "", password: "" });
  const [error, setError] = useState(null);

  useEffect(() => {
    if (user) history.push("/");
  }, [user, history]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFields({ ...fields, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (loading) return;

    try {
      // Loading
      setLoading(true);

      const { email, password } = fields;

      if (!email || !password) throw new firebase.auth.Error("required");

      // Attempt Login
      await signInWithEmailAndPassword(email, password);
    } catch (error) {
      setError(error.code);
      setLoading(false);
    }
  };

  const displayErrorMessage = () => {
    if (!error) return null;

    switch (error) {
      case "auth/required":
        return "Please enter your email and password.";
      case "auth/user-not-found":
      case "auth/wrong-password":
        return "Invalid email address or password.";
      default:
        return "Unable to login at this moment in time. Please try again later.";
    }
  };

  return (
    <div className="h-screen overflow-y-auto p-4 bg-purple-600 flex items-center justify-center">
      <div className="w-96 m-auto">
        {error && (
          <p className="mb-4 bg-red-500 text-white py-2 px-4 rounded">
            {displayErrorMessage()}
          </p>
        )}

        <div className="p-4 bg-white rounded-lg shadow-lg">
          <div className="flex w-20 h-20 mx-auto mb-4 rounded-lg bg-purple-600 bg-gradient-to-r from-indigo-700 to-purple-600">
            <img className="h-3/4 m-auto" src={logo} alt="Table Service App" />
          </div>

          <h1 className="mb-4 text-2xl text-center font-medium">Login</h1>

          <form onSubmit={handleSubmit} noValidate>
            <Input
              label="Email"
              type="email"
              id="email"
              name="email"
              value={fields.email}
              onChange={handleChange}
              autoComplete="username"
              required
            />

            <Input
              label="Password"
              type="password"
              id="password"
              name="password"
              value={fields.password}
              onChange={handleChange}
              autoComplete="current-password"
              required
            />

            <div className="mt-6 text-center">
              <Button type="submit" loading={loading}>
                Submit
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
