import format from "date-fns/format";
import useTimer from "lib/use-timer";

const Timer = () => {
  const timestamp = useTimer();

  return (
    <p className="block text-gray-500">
      Time: {format(timestamp, "HH:mm:ss aa")}
    </p>
  );
};

export default Timer;
