/**
 * Clone a Product
 *
 * @param {DocumentReference} venueRef Venue Reference
 * @param {DocumentReference} ref Product Reference
 * @param {integer} order New Product Order
 * @param {boolean} duplicate Whether the clone is for duplicating purposes
 *
 * @returns {DocumentReference} newRef New Product Reference
 */
export const clone = async (venueRef, ref, order = null, duplicate = false) => {
  // Get Product
  let product = await ref.get();

  if (!product.exists) return null;

  product = product.data();
  product.order = order;

  // Duplicating, append (copy)
  if (duplicate) product.name = `${product.name} (copy)`;

  // Create New Product
  const newRef = await venueRef.collection("products").add(product);

  return newRef;
};
