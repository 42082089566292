import { db } from "lib/firebase";
import { snapshotToData } from "models/helpers";

export const getByID = async (id) => {
  const docRef = db.collection("users").doc(id);

  const doc = await docRef.get();

  return snapshotToData(doc);
};
