import useForm from "lib/use-form";

const initialStateVariant = { name: "", price: "" };
const initialStateExtra = { name: "", price: "" };

const useProductForm = (...args) => {
  const [fields, loading, errors, actions] = useForm(...args);

  const addVariant = () => {
    actions.setFields({
      ...fields,
      variants: [...fields.variants, initialStateVariant],
    });
  };

  const deleteVariant = (index) => {
    actions.setFields({
      ...fields,
      variants: fields.variants.filter((_, i) => i !== index),
    });

    actions.setErrors({
      ...errors,
      variants: errors.variants
        ? errors.variants.filter((_, i) => i !== index)
        : [],
    });
  };

  const addExtra = () => {
    actions.setFields({
      ...fields,
      extras: [...fields.extras, initialStateExtra],
    });
  };

  const deleteExtra = (index) => {
    actions.setFields({
      ...fields,
      extras: fields.extras.filter((_, i) => i !== index),
    });

    actions.setErrors({
      ...errors,
      extras: errors.extras ? errors.extras.filter((_, i) => i !== index) : [],
    });
  };

  return [
    fields,
    loading,
    errors,
    { ...actions, addVariant, deleteVariant, addExtra, deleteExtra },
  ];
};

export default useProductForm;
