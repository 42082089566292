import { useState, useRef, useEffect } from "react";
import clsx from "clsx";

const EditTableCell = (props) => {
  const ref = useRef(null);
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    if (edit) ref.current.select();
  }, [edit, ref]);

  const handleToggle = () => setEdit(true);

  const handleUpdate = async (e) => {
    e.preventDefault();

    let value = ref.current.value;

    await props.table.ref.update({ [props.name]: value });

    setEdit(false);
  };

  const value = props.table[props.name];

  if (edit) {
    return (
      <form onSubmit={handleUpdate}>
        <input
          ref={ref}
          type="text"
          className={clsx(
            "block w-full p-1 bg-white focus:outline-none",
            props.className
          )}
          defaultValue={value}
          onBlur={handleUpdate}
          autoComplete="off"
        />
      </form>
    );
  }

  return (
    <h3
      className="block w-full p-1 hover:cursor-pointer"
      onClick={handleToggle}
    >
      {value}
    </h3>
  );
};

export default EditTableCell;
