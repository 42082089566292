import { Link } from "react-router-dom";
import { useCollectionDataOnce } from "react-firebase-hooks/firestore";
import sortBy from "lodash/sortBy";
import toNumber from "lodash/toNumber";
import * as validate from "lib/validate";
import Input from "components/Forms/Input";
import Textarea from "components/Forms/Textarea";
import Select from "components/Forms/Select";
import Button from "components/Forms/Button";
import Toggle from "components/Forms/Toggle";
import useProductForm from "./use-product-form";
import Variants from "./Variants";
import Extras from "./Extras";

const initialState = {
  active: true,
  name: "",
  description: "",
  price: "",
  manage_stock: false,
  quantity: "",
  variants: [],
  extras: [],
};

const formValidation = {
  name: (value) => validate.required(value),
  price: (value) => validate.number(value),
  quantity: (value) => validate.number(value),
  variants: {
    name: (value) => validate.required(value),
    price: (value) => validate.number(value),
  },
  extras: {
    name: (value) => validate.required(value),
    price: (value) => validate.number(value),
  },
};

const ProductForm = (props) => {
  const [fields, loading, errors, actions] = useProductForm(
    { ...initialState, ...props.initialState },
    {},
    formValidation
  );

  let [categories] = useCollectionDataOnce(
    props.venue.ref.collection("categories").where("parent", "!=", null),
    { idField: "id" }
  );

  categories = sortBy(categories, ["name"]);

  const handleSubmit = (data) => {
    // Format data
    return props.onSubmit({
      ...data,
      price: toNumber(data.price),
      quantity: toNumber(data.quantity),
      variants: data.variants.map((variant) => ({
        ...variant,
        price: toNumber(variant.price),
      })),
      extras: data.extras.map((extra) => ({
        ...extra,
        price: toNumber(extra.price),
      })),
    });
  };

  return (
    <form
      className="relative"
      onSubmit={(e) => actions.handleSubmit(e, handleSubmit)}
      noValidate
    >
      <div className="md:flex md:space-x-4">
        <fieldset className="md:flex-1">
          <div className="mb-4">
            <Toggle
              label="Product Active"
              name="active"
              checked={fields.active}
              onChange={actions.handleChange}
            />
          </div>

          <Select
            label="Category"
            name="category"
            value={fields.category}
            onChange={actions.handleChange}
            options={categories.map((cat) => ({
              value: cat.id,
              label: cat.name,
            }))}
          />

          <Input
            label="Name"
            type="text"
            id="name"
            name="name"
            value={fields.name}
            onChange={actions.handleChange}
            onBlur={actions.handleBlur}
            required
            error={errors.name}
          />

          <Input
            label="Price"
            type="text"
            id="price"
            name="price"
            value={fields.price}
            onChange={actions.handleChange}
            onBlur={actions.handleBlur}
            required
            error={errors.price}
            placeholder="0.00"
          />

          <Textarea
            label="Description"
            id="description"
            name="description"
            value={fields.description}
            onChange={actions.handleChange}
            onBlur={actions.handleBlur}
          />

          <div className="mb-4">
            <Toggle
              label="Manage Stock"
              name="manage_stock"
              checked={fields.manage_stock}
              onChange={actions.handleChange}
            />
          </div>

          {fields.manage_stock && (
            <Input
              label="Quantity"
              type="number"
              id="quantity"
              name="quantity"
              value={fields.quantity}
              onChange={actions.handleChange}
              onBlur={actions.handleBlur}
              required
              error={errors.quantity}
              placeholder="0"
              min="0"
            />
          )}
        </fieldset>

        <fieldset className="md:flex-1">
          <Variants
            variants={fields.variants}
            actions={actions}
            errors={errors}
          />

          <Extras extras={fields.extras} actions={actions} errors={errors} />
        </fieldset>
      </div>

      <div className="mt-8">
        <Button type="submit" loading={loading}>
          Save Product
        </Button>

        <Link
          to={props.back}
          className="ml-4 button border border-gray-300 hover:bg-gray-300 focus:outline-none"
        >
          Cancel
        </Link>
      </div>

      {loading && (
        <div className="absolute z-10 inset-0 w-full h-full bg-white opacity-50 cursor-wait"></div>
      )}
    </form>
  );
};

export default ProductForm;
