import { useVenue } from "lib/UserContext";
import TablesTable from "components/TablesTable";

function Tables() {
  const venue = useVenue();

  return (
    <>
      <h1 className="text-2xl font-semibold mb-4">Tables</h1>

      {!venue ? <p>Select a venue</p> : <TablesTable />}
    </>
  );
}

export default Tables;
