import get from "lodash/get";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Input from "components/Forms/Input";
import Toggle from "components/Forms/Toggle";

const Printers = (props) => {
  const handleLocationChange = (e) => {
    // kebab-case the value
    e.target.value = e.target.value.replace(/\s+/g, "-").toLowerCase();

    props.actions.handleChange(e);
  };

  return (
    <fieldset className="space-y-4 mb-4">
      <legend className="block w-full text-lg font-semibold">
        Printers Setup
      </legend>

      <div>
        <Toggle
          label="Enable Star CloudPRNT"
          name="printing.cloudprnt"
          checked={props.fields.printing.cloudprnt}
          onChange={props.actions.handleChange}
        />
      </div>

      {props.fields.printing.cloudprnt && props.fields.id && (
        <div className="space-y-4">
          {props.fields.printers.map((printer, index) => (
            <div
              key={`printer-${index}`}
              className="relative grid gap-4 p-4 rounded bg-gray-100"
            >
              <button
                type="button"
                className="absolute top-0	right-0 w-8 h-8 text-sm transition hover:bg-gray-300 focus:outline-none"
                onClick={() => props.actions.deletePrinter(index)}
              >
                <FontAwesomeIcon icon="times" />
              </button>

              <div className="flex flex-nowrap space-x-4">
                <div className="flex-1">
                  <Input
                    label="Location"
                    type="text"
                    id={`printers.${index}.location`}
                    name={`printers.[${index}].location`}
                    data-validate="printers.location"
                    value={printer.location}
                    onChange={handleLocationChange}
                    onBlur={props.actions.handleBlur}
                    required
                    error={get(props.errors, `printers.[${index}].location`)}
                  />
                </div>
                <div className="flex-initial pt-8">
                  <Toggle
                    label="Automatic Printing"
                    id={`printers.${index}.automatic`}
                    name={`printers.[${index}].automatic`}
                    checked={printer.automatic}
                    onChange={props.actions.handleChange}
                  />
                </div>
              </div>

              <Input
                label="CloudPRNT Server URL"
                type="text"
                readOnly
                value={`https://us-central1-tableservice-e1e5b.cloudfunctions.net/cloudPrnt?venue=${props.fields.id}&location=${printer.location}`}
                onClick={(e) => e.target.select()}
              />
            </div>
          ))}

          <button
            type="button"
            onClick={props.actions.addPrinter}
            className="button-gray"
          >
            <FontAwesomeIcon className="mr-1" icon="plus" /> Add Printer
          </button>
        </div>
      )}

      <div>
        <Toggle
          label="Enable Star PassPRNT"
          name="printing.passprnt"
          checked={props.fields.printing.passprnt}
          onChange={props.actions.handleChange}
        />
      </div>
    </fieldset>
  );
};

export default Printers;
