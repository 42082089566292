import { useState } from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import { toast } from "react-toastify";
import Button from "components/Forms/Button";
import useTakeawayForm from "./use-takeaway-form";
import Delivery from "./Delivery";
import Collection from "./Collection";

const tabs = [
  { label: "Delivery", Component: Delivery },
  { label: "Collection", Component: Collection },
];

export default function Takeaway(props) {
  const [active, setActive] = useState(tabs[0]);

  const [fields, loading, errors, actions] = useTakeawayForm(
    props.initialState
  );

  const handleSubmit = async (data) => {
    try {
      await props.onSubmit(data);

      toast.success(`Information has been updated!`);
    } catch (error) {
      console.error(error);
      toast.error(error.message);
    } finally {
      actions.setLoading(false);
    }
  };

  return (
    <>
      <TabsNav active={active} setActive={setActive} />

      <form
        className="relative"
        onSubmit={(e) => actions.handleSubmit(e, handleSubmit)}
        noValidate
      >
        <AnimatePresence exitBeforeEnter initial={false}>
          <motion.div
            key={`tab-${active.label}`}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
          >
            <active.Component
              fields={fields}
              actions={actions}
              errors={errors}
            />
          </motion.div>
        </AnimatePresence>

        <div className="py-4 border-t border-gray-300">
          <Button type="submit" loading={loading}>
            Save Venue
          </Button>

          <Link
            to="/venues"
            className="ml-4 button border border-gray-300 hover:bg-gray-300 focus:outline-none"
          >
            Cancel
          </Link>
        </div>

        {loading && (
          <div className="absolute z-10 inset-0 w-full h-full bg-white opacity-50 cursor-wait"></div>
        )}
      </form>
    </>
  );
}

function TabsNav(props) {
  return (
    <nav className="mb-4 py-2 border-b border-gray-300 overflow-x-auto">
      <ul className="flex space-x-2">
        {tabs.map((tab) => {
          return (
            <li key={`tab-link-${tab.label}`}>
              <button
                type="button"
                onClick={() => props.setActive(tab)}
                className={clsx(
                  "button",
                  props.active === tab
                    ? "bg-purple-600 text-white"
                    : "hover:bg-gray-200"
                )}
              >
                {tab.label}
              </button>
            </li>
          );
        })}
      </ul>
    </nav>
  );
}
