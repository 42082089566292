import Subcategories from "../Subcategories";

const Categories = (props) => {
  return (
    <>
      <h2 className="text-xl font-semibold mb-2">{props.name}</h2>

      <div className="mb-4 space-y-4">
        {Object.entries(props.subcategories).map(
          ([subcategory, products], i) => {
            return (
              <Subcategories
                key={`subcategory-${i}`}
                name={subcategory}
                products={products}
              />
            );
          }
        )}
      </div>
    </>
  );
};

export default Categories;
