import { Route, Redirect } from "react-router-dom";
import { useUser } from "lib/UserContext";
import { isOwner } from "lib/helpers";

function OwnerRoute({ children, ...rest }) {
  const user = useUser();

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isOwner(user) ? (
          children
        ) : (
          <Redirect to={{ pathname: "/", state: { from: location } }} />
        )
      }
    />
  );
}

export default OwnerRoute;
