import get from "lodash/get";
import Input from "components/Forms/Input";
import Toggle from "components/Forms/Toggle";

const Details = (props) => {
  return (
    <>
      <div className="mb-4">
        <Toggle
          label="Set as Takeaway"
          name="takeaway"
          checked={props.fields.takeaway}
          onChange={props.actions.handleChange}
        />
      </div>

      <div className="mb-4">
        <Toggle
          label="Set as Hotel"
          name="hotel"
          checked={props.fields.hotel}
          onChange={props.actions.handleChange}
        />
      </div>

      <div className="mb-4">
        <Input
          label="Name"
          type="text"
          id="name"
          name="name"
          value={props.fields.name}
          onChange={props.actions.handleChange}
          onBlur={props.actions.handleBlur}
          required
          error={props.errors.name}
        />
      </div>

      <fieldset className="space-y-4 mb-4">
        <legend className="block w-full text-lg font-semibold">Address</legend>

        <Input
          label="Address 1"
          type="text"
          id="address1"
          name="address1"
          value={props.fields.address1}
          onChange={props.actions.handleChange}
          onBlur={props.actions.handleBlur}
          error={props.errors.address1}
        />

        <Input
          label="Address 2"
          type="text"
          id="address2"
          name="address2"
          value={props.fields.address2}
          onChange={props.actions.handleChange}
          onBlur={props.actions.handleBlur}
          error={props.errors.address2}
        />

        <Input
          label="Town"
          type="text"
          id="town"
          name="town"
          value={props.fields.town}
          onChange={props.actions.handleChange}
          onBlur={props.actions.handleBlur}
          error={props.errors.town}
        />

        <Input
          label="City"
          type="text"
          id="city"
          name="city"
          value={props.fields.city}
          onChange={props.actions.handleChange}
          onBlur={props.actions.handleBlur}
          error={props.errors.city}
        />

        <Input
          label="Postcode"
          type="text"
          id="postcode"
          name="postcode"
          value={props.fields.postcode}
          onChange={props.actions.handleChange}
          onBlur={props.actions.handleBlur}
          error={props.errors.postcode}
        />

        <div className="grid grid-cols-2 gap-4">
          <Input
            label="Latitude"
            type="text"
            id="_lat"
            name="location._lat"
            value={props.fields.location._lat}
            onChange={props.actions.handleChange}
            onBlur={props.actions.handleBlur}
            error={get(props.errors, "location._lat")}
          />

          <Input
            label="Longitude"
            type="text"
            id="_long"
            name="location._long"
            value={props.fields.location._long}
            onChange={props.actions.handleChange}
            onBlur={props.actions.handleBlur}
            error={get(props.errors, "location._long")}
          />
        </div>
      </fieldset>
    </>
  );
};

export default Details;
