import { useCallback, useEffect, useMemo, useState } from "react";
import move from "array-move";

const useCategories = (initialState) => {
  const [state, setState] = useState(initialState);

  const active = useMemo(
    () => initialState.filter((c) => c.active),
    [initialState]
  );
  const inactive = useMemo(
    () => initialState.filter((c) => c.active === false),
    [initialState]
  );

  useEffect(() => {
    setState(initialState);
  }, [active.length, inactive.length]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setState(initialState);
  }, [initialState.length]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleMove = useCallback(
    (dragIndex, hoverIndex) => {
      // dragIndex = from, hoverIndex = to
      setState(move(state, dragIndex, hoverIndex));
    },
    [state]
  );

  const actions = { handleMove };

  return [state, actions];
};

export default useCategories;
