import { Link } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { db } from "lib/firebase";
import Search from "components/Search";
import VenueRow from "./VenueRow";
import useVenueSearch from "./use-venue-search";
import Toggle from "components/Forms/Toggle";

const VenuesTable = () => {
  const [allVenues] = useCollectionData(
    db.collection("venues").orderBy("name"),
    {
      idField: "id",
      refField: "ref",
    }
  );

  const [venues, search, inactive, actions] = useVenueSearch(allVenues);

  return (
    <AnimatePresence exitBeforeEnter>
      {allVenues && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
        >
          <div className="flex items-center justify-between space-x-2 mb-4">
            <div className="flex-1">
              <Search value={search} onChange={actions.handleSearch} />
            </div>
            <div className="flex-initial">
              <Toggle
                label="Show Inactive"
                name="inactive"
                checked={inactive}
                onChange={actions.handleInactiveToggle}
              />
            </div>
          </div>

          <div className="mb-4">
            {venues.length ? (
              venues.map((venue) => (
                <VenueRow key={`venue-${venue.id}`} venue={venue} />
              ))
            ) : (
              <p className="text-gray-500">No Venues Found</p>
            )}
          </div>

          <Link to="/venues/create" className="button-purple">
            <FontAwesomeIcon className="mr-1" icon="plus" /> Add Venue
          </Link>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default VenuesTable;
