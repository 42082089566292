import { useLocation } from "react-router-dom";
import { useVenue } from "lib/UserContext";
import Timer from "components/Timer";
import OrdersTable from "components/OrdersTable";

const OrdersPage = () => {
  const location = useLocation();
  const venue = useVenue();

  // Close the return back from passprnt (Star Printer callback)
  if (location.search.includes("passprnt_message")) window.close();

  const isCompleted = location.pathname.includes("completed");

  return (
    <>
      <header className="flex items-center justify-between space-x-2 mb-2">
        <h1 className="text-2xl font-semibold">Orders</h1>

        <Timer />
      </header>

      {!venue ? <p>Select a venue</p> : <OrdersTable completed={isCompleted} />}
    </>
  );
};

export default OrdersPage;
