import capitalize from "lodash/capitalize";
import { useUser } from "lib/UserContext";
// import userImage from "images/user.svg";

function User() {
  const user = useUser();

  return (
    <div className="mt-auto">
      <p className="font-semibold text-black">{user.name}</p>
      <p className="text-sm font-medium text-gray-500">
        {capitalize(user.role)}
      </p>
    </div>
  );

  // return (
  //   <div className="mt-auto flex items-center space-x-4">
  //     <img
  //       className="flex-shrink-0 w-16 h-16 overflow-hidden p-3 rounded-full bg-blue-50"
  //       src={userImage}
  //       alt={user.name}
  //     />

  //     <div className="text-left">
  //       <p className="font-semibold text-black">{user.name}</p>
  //       <p className="text-sm font-medium text-gray-500">
  //         {capitalize(user.role)}
  //       </p>
  //     </div>
  //   </div>
  // );
}

export default User;
