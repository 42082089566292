import { useState, useEffect } from "react";
import clsx from "clsx";
import logo from "images/logo.svg";

function PageLoader(props) {
  const [show, setShow] = useState(!props.wait);

  useEffect(() => {
    const waitTimer = setTimeout(() => setShow(true), props.wait);

    return () => {
      clearTimeout(waitTimer);
    };
  }, [props.wait]);

  if (!show) return null;

  return (
    <div
      className={clsx(
        props.full ? "relative bg-white h-screen" : "absolute inset-0"
      )}
    >
      <div className="absolute inset-0 m-auto flex items-center justify-center w-32 h-32">
        <div className="animate-scale absolute z-0 h-full w-full rounded-lg bg-purple-600 bg-gradient-to-r from-indigo-700 to-purple-600"></div>

        <img
          className="relative z-10 h-3/4 m-auto"
          src={logo}
          alt="Table Service App"
        />
      </div>
    </div>
  );
}

export default PageLoader;
