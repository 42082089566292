import { ToastContainer, Slide } from "react-toastify";

const contextClass = {
  default: "bg-purple-600 text-white",
  success: "bg-green-500",
  error: "bg-red-500",
};

const Toast = () => (
  <ToastContainer
    transition={Slide}
    position="top-right"
    autoClose={4000}
    hideProgressBar
    closeOnClick
    pauseOnFocusLoss
    pauseOnHover
    draggable
    toastClassName={({ type }) =>
      contextClass[type || "default"] +
      " relative flex p-2 mb-2 rounded justify-between overflow-hidden cursor-pointer"
    }
  />
);

export default Toast;
