import capitalize from "lodash/capitalize";
import round from "lodash/round";
import compact from "lodash/compact";
import format from "date-fns/format";
import formatDistanceStrict from "date-fns/formatDistanceStrict";
import { acceptsPayments, currency } from "lib/helpers";
import useTimer from "lib/use-timer";

const OrderDetails = (props) => {
  const timestamp = useTimer();

  const subtotal = props.order.subtotal || props.order.total;
  const tip = round(props.order.tip || 0, 2);
  const deliveryCost = round(props.order.deliveryCost || 0, 2);

  const total = round(props.order.subtotal, 2) + tip + deliveryCost;

  return (
    <>
      <p className="text-gray-500 mb-2">
        Time: {format(timestamp, "HH:mm:ss aa")}
      </p>

      <div className="leading-relaxed mb-4">
        {!props.venue?.takeaway && (
          <p>
            <b>Table:</b> {props.order.table}
          </p>
        )}
        <p>
          <b>Date:</b> {format(props.order.date, "EEE do LLL - HH:mm:ss aa")} (
          {formatDistanceStrict(props.order.date, timestamp, {
            addSuffix: true,
            roundingMethod: "floor",
          })}
          )
        </p>

        <p>
          <b>Subtotal:</b> {currency(subtotal)}
        </p>

        {props.venue.tips && (
          <p>
            <b>Tip:</b> {currency(tip)}
          </p>
        )}

        {props.order.takeaway === "delivery" && (
          <p>
            <b>Delivery:</b>{" "}
            {deliveryCost === 0 ? "FREE" : currency(deliveryCost)}
          </p>
        )}

        <p>
          <b>Total:</b> {currency(total)}
        </p>

        {props.order.notes && (
          <p>
            <b>Notes:</b> {props.order.notes}
          </p>
        )}

        {acceptsPayments(props.venue) && (
          <p>
            <b className="mr-1">Payment:</b>
            <OrderPaymentDetails payment={props.order.payment} />
          </p>
        )}

        {props.venue?.takeaway && (
          <>
            <p>
              <b>Order Type:</b> {capitalize(props.order?.takeaway)}
            </p>

            {props.order.takeaway === "delivery" && (
              <p>
                <b>Address:</b> {formatAddress(props.order?.address)}
              </p>
            )}

            {props.order.takeaway === "delivery" && (
              <p>
                <b>Phone Number:</b> {props.order?.telephone}
              </p>
            )}

            {props.order.takeaway === "collection" && (
              <p>
                <b>Customer Name:</b> {props.order?.customerName}
              </p>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default OrderDetails;

const OrderPaymentDetails = (props) => {
  if (!props.payment) return <em>Take Payment</em>;

  return <span>Paid via {capitalize(props.payment.provider)}</span>;
};

function formatAddress(address) {
  let parts = [
    address?.line_1,
    address?.line_2,
    address?.town_or_city,
    address?.county,
    address?.postcode,
  ];

  parts = compact(parts);

  return parts.join(", ");
}
