import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useDrag, useDrop } from "react-dnd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { useVenue } from "lib/UserContext";
import CategoryName from "../CategoryName";

const SubCategory = (props) => {
  const venue = useVenue();
  const ref = useRef(null);
  const mounted = useRef(null);

  let [products] = useCollectionData(
    venue.ref
      .collection("products")
      .where("category", "==", props.category.ref),
    {
      idField: "id",
      refField: "ref",
    }
  );

  // Update Firestore when order changes
  useEffect(() => {
    const updateFirestoreOrder = async () => {
      await props.category.ref.update({ order: props.index });
    };

    if (mounted.current !== null && mounted.current !== props.index) {
      updateFirestoreOrder();
    }

    mounted.current = props.index;
  }, [props.category.ref, props.index]);

  const [, drop] = useDrop({
    accept: `sub-category-${props.parent.id}`,
    hover(item, monitor) {
      if (!ref.current) return;

      const dragIndex = item.index;
      const hoverIndex = props.index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) return;

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      props.onMove(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: `sub-category-${props.parent.id}`,
    item: () => {
      return {
        id: props.category.id,
        index: props.index,
      };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  const isActive =
    products && (products.some((p) => p.active) || products.length === 0);

  return (
    <motion.div
      ref={ref}
      layout
      transition={{ duration: 0.2 }}
      className={`flex space-x-1 mb-2 p-2 bg-white rounded shadow-sm hover:cursor-pointer ${
        isDragging && "opacity-50"
      } ${!isActive && "opacity-50"}`}
    >
      <div className="flex-1">
        <CategoryName category={props.category} />
      </div>

      <Link
        to={`/menu/${props.category.id}`}
        className="flex items-center justify-center w-6 h-6 text-sm text-gray-400 rounded transition hover:text-gray-700 hover:bg-gray-200 focus:outline-none"
      >
        <FontAwesomeIcon icon="eye" />
      </Link>

      <button
        type="button"
        className="flex items-center justify-center w-6 h-6 text-sm text-gray-400 rounded transition hover:text-gray-700 hover:bg-gray-200 focus:outline-none"
        onClick={props.onDelete}
      >
        <FontAwesomeIcon icon="times" />
      </button>
    </motion.div>
  );
};

export default SubCategory;
